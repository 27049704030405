/**
 * ...
 *
 * Description: ...
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-16
 * 
 */

import React, { useState, useEffect } from 'react';

import { Button, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { StatisticsModeledUCs } from './eco_analytics_charts';

import { fetchUseCaseData } from '../db_mgmt/read';

import { StatisticsDashboard, computeEcoStats } from './eco_stats';

import { transformToNewStructure } from '../db_mgmt/transform_data';

import InfoModal from '../elems/elem_info_modal';
import ModalCompare from './eco_modal_compare';
import EcoUCDashboard from './eco_uc_dashboard';

import UseCaseMap from '../elems/elem_map';

const EcoAnalytics = () => {
  
  const [showModal, setShowModal] = useState(false);
  const info = 'This page provides an overview of the Ecosystem Mapper tool. The tool allows you to analyze, classify, and evaluate use cases. You can get insights about the modeled use cases, analyze a specific use case in its corresponding ecosystem, and generalize a model.';

  const [isModalCompare, setIsModalCompare] = useState(false);
  //const [isCompare, setIsCompare] = useState(false);

  const [activeButton, setActiveButton] = useState('initial');
  const [data, setData] = useState([]);

  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [comparisonType, setComparisonType] = useState('');
  const [selectedComparisonUseCases, setSelectedComparisonUseCases] = useState([]);

  /*
  useEffect(() => {
    const getData = async () => {
      const data = await fetchUseCaseData();
      setData(data);
    };

    getData();        
  }, []);
  */

  useEffect(() => {
    const getData = async () => {
        const data = await fetchUseCaseData(); // Fetch the data
    
        // Transform all use cases to the new structure if needed
        const transformedData = data.map(useCase => transformToNewStructure(useCase));
        //console.log('Transformed data:', transformedData);
        setData(transformedData);
    };
  
    getData();
  }, []);

  const stats = computeEcoStats(data);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleClear = () => {
    setActiveButton('initial');
    //setIsCompare(false);
  }

  const handleNumbers = () => { 
    setActiveButton('numbers');   
    //setIsCompare(false);
  };

  const handleCompare = () => {
    //setIsCompare(true);
    setActiveButton('compare');
    //handleButtonClick('compare');
  };

  const handleModalInfo = () => {
    setShowModal(!showModal);
  };

  const handleModalCompare = () => {
    setIsModalCompare(!isModalCompare);
  }

  return (
    <div>
      <Row>
        {/* Headline and Toolbar */}
        <Col md={3}>
            <div>
                <h3 className="text-start">Ecosystem Mapper</h3>
                <h6 className="text-start">Analyze, Classify & Evaluate</h6>
            </div>
        </Col>
        {/* Toolbar */}
        <Col md={9} className="d-flex justify-content-end align-items-center">
          <OverlayTrigger placement="top" overlay={<Tooltip>Overview of tools and statistics of the modeled use case.</Tooltip>}>
            <Button 
              style={{ marginLeft: '10px' }} 
              variant={activeButton === 'initial' ? "primary" : "outline-secondary"} 
              onClick={() => {
                handleButtonClick('initial');
              }}
            >
              <i className="bi bi-door-open"></i>
            </Button>
          </OverlayTrigger>
          <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>  
          <OverlayTrigger placement="top" overlay={<Tooltip>Get insights about the modeled use cases.</Tooltip>}>
            <Button 
              style={{ marginLeft: '10px' }} 
              variant={activeButton === 'numbers' ? "primary" : "outline-secondary"} 
              onClick={() => {
                handleButtonClick('numbers');
                handleNumbers();
              }}
            >
              <i className="bi bi-graph-up"></i>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Map a specific use case in the larger ecosystem.</Tooltip>}>
            <Button 
              variant={activeButton === 'compare' ? "primary" : "outline-secondary"} 
              style={{ marginLeft: '10px' }} 
              onClick={() => {
                handleModalCompare();
               }}>
              <i className="bi bi-layers"></i>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Look up generalized model.</Tooltip>}>
            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
              <i className="bi bi-magic"></i>
            </Button>
          </OverlayTrigger>                   
          <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>               
          <OverlayTrigger placement="top" overlay={<Tooltip>Learn more about this page.</Tooltip>}>
            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleModalInfo}>
                <i className="bi bi-info-circle"></i>
            </Button>
          </OverlayTrigger>                 
        </Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>

          {/* Initial charts */}
          {activeButton === 'initial' && (
            <>          
              <StatisticsDashboard stats={stats} />               
              {/* Analytics modules and tools */}
              <Row style={ { marginTop: '20px', marginBottom:'30px', textAlign:'left' } }>                  
                {/* Overview of all modeled use cases */}
                <Col md={4}>
                  <Card className='h-100'>
                    <Card.Body>
                      <div>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Provides statistics about the modeled use cases.</Tooltip>}>
                          <Card.Text>
                            <span><i className="bi bi-lightbulb"></i> Insights about all modeled use cases</span>
                          </Card.Text>                      
                        </OverlayTrigger>
                        <span>
                          <small>
                            This module provides an overview of the modeled use cases, including the number of use cases, actors, components, and actions.
                            It also provides an overview of the application areas and the maturity levels of the use cases.
                          </small>
                        </span>           
                      </div>                                                                    
                    </Card.Body>
                    <Card.Footer>
                      <div align='right'>
                        <Button variant="outline-secondary" disabled>Learn more</Button>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Provides statistics about the modeled use cases.</Tooltip>}>
                          <Button variant="success" style={ { marginLeft:"15px" } } onClick={handleNumbers}>Get insights</Button>
                        </OverlayTrigger>
                      </div> 
                    </Card.Footer>
                  </Card>
                </Col>
                {/* Ecosystem Analysis */}
                <Col md={4}>
                  <Card className='h-100'>
                    <Card.Body>
                      <div>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Select and analyze a specific use case in its corresponding ecosystem.</Tooltip>}>
                          <Card.Text>
                            <span><i className="bi bi-geo-alt"></i> Analyze a use case in the domain ecosystem</span>
                          </Card.Text>                      
                        </OverlayTrigger>
                        <span>
                          <small>
                            Select a use and identify matching actors and components involved, and how these are linked to each other.
                            Analyze the distribution of elements and detect outliers of the use case and within its application areas and derive a model for implementation.
                          </small>
                        </span>           
                      </div>                                                                    
                    </Card.Body>
                    <Card.Footer>
                      <div align='right'>
                        <Button variant="outline-secondary" disabled>Learn more</Button>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Start Comparator Module, allowing you to analyze the use case in the ecosystem.</Tooltip>}>
                          <Button variant="success" style={ { marginLeft:"15px" } } onClick={handleModalCompare}>Start Ecosystem Analysis</Button>
                        </OverlayTrigger>
                      </div> 
                    </Card.Footer>
                  </Card>
                </Col>
                {/* Model Generalization */}
                <Col md={4}>
                  <Card className='h-100'>                                  
                    <Card.Body>
                      <div>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Model Generalization: detect patterns and commonalities</Tooltip>}>
                          <Card.Text>
                            <span><i className="bi bi-magic"></i> Generalize Model</span>
                          </Card.Text>
                        </OverlayTrigger>
                        <span>
                          <small>
                            Generalize from the created use cases modeled towards a <b>reference model for the specific domain in question</b> by detecting patterns and commonalities among the use cases. 
                            This will help in identifying the common actors, components, and actions among the use cases.
                          </small>
                        </span>
                      </div>
                    </Card.Body>
                    <Card.Footer>
                        <div align='right'>
                          <Button variant="outline-secondary" disabled>Learn more</Button>
                          <Button variant="success" style={ { marginLeft:"15px" } } disabled>Start Model Generalization</Button>
                        </div> 
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
              <Row align='center' style={{ marginTop: '15px' }}>
                <Col md={12}>
                  <UseCaseMap useCases={data} />
                </Col>
              </Row>
            </>
          )}   

          {/* Statistics of all modeled use cases */}        
          {activeButton === 'numbers' && (
            <>
                <StatisticsModeledUCs data={data}  handleClear={handleClear} />         
            </>
          )}                      

          {/* Comparator module that captures use case analysis / mapping */}
          {activeButton === 'compare' && (
            <EcoUCDashboard 
              mode={'Compare use cases'}
              data={data} 
              selectedUseCase={selectedUseCase} 
              setSelectedUseCase={setSelectedUseCase}
              comparisonType={comparisonType}
              setComparisonType={setComparisonType}
              selectedComparisonUseCases={selectedComparisonUseCases}
              setSelectedComparisonUseCases={setSelectedComparisonUseCases}
            />
          )}

        </Col>
        <Col md={1}></Col>
      </Row>

      {showModal && (
        <InfoModal 
          onClose={handleModalInfo} 
          info={info}
        />
      )}
      {isModalCompare && (
        <ModalCompare
          show={isModalCompare}
          onHide={() => setIsModalCompare(false)}
          data={data}
          selectedUseCase={selectedUseCase}
          setSelectedUseCase={setSelectedUseCase}
          comparisonType={comparisonType}
          setComparisonType={setComparisonType}
          selectedComparisonUseCases={selectedComparisonUseCases}
          setSelectedComparisonUseCases={setSelectedComparisonUseCases}
          handleStartAnalysis={handleCompare}
        />
      )}
    </div>
  );
};

export default EcoAnalytics;