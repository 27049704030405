import React from 'react';

import { Container, Button, Row } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

export default function About(props) {

  const navigate = useNavigate();
  const navigateApproach = () => {
    navigate('/Approach');
  };

  const navigateStudy = () => {
    navigate('/Participate');
  }

  return (
    <Container fluid>
        {/* Intro */}
        <Row align='center'>      
            <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>A bit of Context</b></h1>
            <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
            <i>UCM</i> is a tool designed to explore required components, data and actors to conceptualize and build solutions, with a focus on Autonomous Mobility (AM) and Transportation.
            </p>
        </Row>
        {/* Classification */}         
        <Row align='center'>
            <h3 style={{marginTop: '30px', marginBottom: '30px'}}>Classification</h3>
            <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
            It builts upon two developed semantic models, that is the Generic Use Case Ontology (GUCO) modules and the Platform Economy (PE) for Autonomous Mobility (AM) Ontology (PEAMONTO) module.
            <i>peccam</i> is a solution builder in the realm of autonomous mobility. It addresses the distributed artificial intelligence (DAI) approach for Cooperative, Connected and Automated Mobility (CCAM) 
            in the Platform Economy (PE) framework.      
            </p>                        
        </Row>
        {/* Learn about the approach */}
        <Row align='center'>
          <div>
            <Button variant="outline-primary" style={{ marginRight: '10px' }} onClick={navigateApproach}>Learn about the approach</Button>
            <Button variant="outline-primary">Publications</Button>
          </div>            
        </Row>
        {/* The research */}
        <Row align='center'>      
            <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>The research</b></h1>
            <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
            This work is motivated from PhD research activities and is conducted alongside the BeIntelli research project on AI in Mobility based on Platform Economy.

            Landmarks are: semantic models, ontologies, digital platforms, CCAM, Distributed AI (DAI), MaaS, Smart City, platform economy, network effects, public traffic space.
            </p>
        </Row>  
        {/* Navigate */}
        <Row align='center'>
            <div>            
                <Button variant="primary" style={{ marginRight: '10px' }} onClick={navigateStudy}>Participate</Button>
            </div>                
        </Row>
      </Container>
    );
};

//export default About;
export { default as About } from './About';