/**
 * Use Case Repository
 *
 * Description: Provides all modeled and stored use cases in the repository.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-09
 * 
 */

import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import renderCardView from './uc_stacked_view';
import renderAccordionView from './uc_list_view';

import { UserInformation } from '../auth_mgmt/auth_handling';

//import AnalyticsUCDashboard from '../analytics_handling/analytics_uc_dashboard'; //uc_inspect';
import EcoUCDashboard from '../eco_analytics_handling/eco_uc_dashboard';

import { fetchUseCaseData } from '../db_mgmt/read';
import { deleteUseCaseData } from '../db_mgmt/write';
import UCModalDelete from './uc_modal_delete';
import ModalCreateMode from '../create_handling/create_modal_mode';

import { transformToNewStructure } from '../db_mgmt/transform_data';

const filterUserUseCases = (useCases, userId) => {
    return useCases.filter(useCase => useCase.user_id.value === userId);
};

const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
};

const RepositoryView = ({ showUserCases, setShowUserCases }) => { // data, setData, useCaseDetails

    const [showModalCreateMode, setShowModalCreateMode] = useState(false);

    const [useCases, setUseCases] = useState([]);
    const [filteredUseCases, setFilteredUseCases] = useState([]);
    // used for selecting multiple use cases
    const [selectedUseCases, setSelectedUseCases] = useState([]);
    
    // used for inspecting a use case
    //const [selectedUseCaseId, setSelectedUseCaseId] = useState(null);
    const [viewMode, setViewMode] = useState('card'); // 'card', 'accordion', 'inspect'

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const maxItemsAccordion = 6;
    const maxItemsCard = 9;
    const itemsPerPage = (viewMode === 'card') ? maxItemsCard : maxItemsAccordion;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredUseCases.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredUseCases.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const getUserID = () => {
        return UserInformation().uid || '';
    };

    const [isDelete, setIsDelete] = useState(false);
    const [deleteUseCase, setDeleteUseCase] = useState({});

    // filter states
    const [isFilter, setIsFilter] = useState(false);
    const [isFilterTags, setIsFilterTags] = useState(false);
    const [isFilterDate, setIsFilterDate] = useState(false);
    const [isFilterScore, setIsFilterScore] = useState(false);
    const [isFilterCreator, setIsFilterCreator] = useState(false);
    const [isFilterLocation, setIsFilterLocation] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [filterKeyword, setFilterKeyword] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedContinent, setSelectedContinent] = useState('');
    const [selectedCreator, setSelectedCreator] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);

    const [uniqueCreator, setUniqueCreator] = useState([]);

    const [selectedKeyword, setSelectedKeyword] = useState('');
    const uniqueKeywords = [...new Set(useCases.flatMap(useCase => useCase.tags.value))]; // currentItems

    // Individual use case inspection / dashboard handling
    const [selectedUseCase, setSelectedUseCase] = useState({});
    const [comparisonType, setComparisonType] = useState('all'); // initial state in the dashboard view
    const [selectedComparisonUseCases, setSelectedComparisonUseCases] = useState([]);

    const navigate = useNavigate();
    // creator, cockpit
    const handleCreateMode = () => {
        setShowModalCreateMode(true);
    }

    //const [useCaseData, setUseCaseData] = useState([]);
    /*
    useEffect(() => {
        const getData = async () => {
          const data = await fetchUseCaseData();
          setUseCases(data);
          setFilteredUseCases(data);
        };
    
        getData();        
    }, []);
    */

    useEffect(() => {
        const getData = async () => {
            const data = await fetchUseCaseData(); // Fetch the data
        
            // Transform all use cases to the new structure if needed
            const transformedData = data.map(useCase => transformToNewStructure(useCase));
            //console.log('Transformed data:', transformedData);
            setUseCases(transformedData);
            setFilteredUseCases(transformedData);
        };
      
        getData();
    }, []);

    useEffect(() => {
        if (showUserCases) {
          const userId = getUserID();
          const userUseCases = filterUserUseCases(useCases, userId);
          setFilteredUseCases(userUseCases);
        } else {
          setFilteredUseCases(useCases);
        }
    }, [showUserCases, useCases]);

    // get unique list of users
    useEffect(() => {
        setUniqueCreator([...new Set(useCases.map(useCase => useCase.user.value))]);
    }, [useCases]);

    // Filter use cases

    // Handle Tag Selection (multi-select)
    const handleTagSelection = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter(t => t !== tag)); // Remove tag if already selected
        } else {
            setSelectedTags([...selectedTags, tag]); // Add tag if not already selected
        }
    };

    const handleFilterCreator = () => {
        setIsFilterCreator(!isFilterCreator);
        setIsFilterDate(false);
        setIsFilterScore(false);
        setIsFilterTags(false);
        setIsSearch(false);
        setIsFilterLocation(false);
    };

    // Filter by Creator
    const handleCreatorChange = (e) => {
        setSelectedCreator(e.target.value);
    };

    // Filter location
    const handleFilterLocation = () => {
        setIsFilterLocation(!isFilterLocation);
        setIsFilterDate(false);
        setIsFilterScore(false);
        setIsFilterTags(false);
        setIsSearch(false);
        setIsFilterCreator(false);
    };

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    const handleContinentChange = (e) => {
        setSelectedContinent(e.target.value);
    };

    // Combine Filters
    useEffect(() => {
        let filtered = useCases;
        if (showUserCases) {
            filtered = filterUserUseCases(useCases, getUserID());
        }

        // Filter by keyword
        if (filterKeyword) {
            filtered = filtered.filter(useCase => 
                useCase.tags.value.some(tag => tag.toLowerCase().includes(filterKeyword.toLowerCase()))
            );
        }

        // Filter by date
        if (filterDate) {
            filtered = filtered.filter(useCase => {
                const createdDate = formatTimestamp(useCase.created?.value);                
                return createdDate === filterDate;
            });
        }

        // Filter by selected tags (multi-select)
        if (selectedTags.length > 0) {
            filtered = filtered.filter(useCase => 
                selectedTags.some(tag => useCase.tags.value.includes(tag))
            );
        }

        // Filter by city
        if (selectedCity) {
            filtered = filtered.filter(useCase => 
                Array.isArray(useCase.city.value) && useCase.city.value.some(city => city === selectedCity)
            );
        }

        // Filter by country
        if (selectedCountry) {
            filtered = filtered.filter(useCase => 
                Array.isArray(useCase.country.value) && useCase.country.value.some(country => country === selectedCountry)
            );
        }

        // Filter by continent
        if (selectedContinent) {
            filtered = filtered.filter(useCase => 
                Array.isArray(useCase.continents.value) && useCase.continents.value.some(continent => continent === selectedContinent)
            );
        }

        // Filter by creator/user
        if (selectedCreator) {
            filtered = filtered.filter(useCase => useCase.user.value === selectedCreator);
        }        

        setFilteredUseCases(filtered);

    }, [filterKeyword, filterDate, selectedTags, selectedCreator, useCases, showUserCases, selectedCity, selectedCountry, selectedContinent]);

    const handleSearch = (e) => {
        setIsSearch(!isSearch);
        setIsFilterDate(false);
        setIsFilterScore(false);
        setIsFilterTags(false);
    
        const searchKeyword = e.target.value.toLowerCase().trim();
    
        if (!searchKeyword) {
            setFilteredUseCases(useCases);
            return;
        }
    
        // Filter the use cases based on the search keyword
        const filtered = useCases.filter(useCase => {
            const titleMatch = useCase.title?.value?.toLowerCase().includes(searchKeyword);
            const acronymMatch = useCase.acronym?.value?.toLowerCase().includes(searchKeyword);
            const descriptionMatch = useCase.description?.value?.toLowerCase().includes(searchKeyword);
            const tagsMatch = useCase.tags?.value?.some(tag => tag.toLowerCase().includes(searchKeyword));
    
            return titleMatch || acronymMatch || descriptionMatch || tagsMatch;
        });
    
        setFilteredUseCases(filtered);
    };

    const handleFilter = () => {
        setIsFilter(!isFilter);
        setIsFilterDate(false);
        setIsFilterScore(false);
        setIsFilterTags(false);
        setIsSearch(false);      
        setIsFilterCreator(false);  

        setIsFilterLocation(false);
        setSelectedCity('');
        setSelectedCountry('');
        setSelectedContinent('');
    };

    const handleSortDown = () => {
        setFilteredUseCases([...filteredUseCases].sort((a, b) => a.title.value.localeCompare(b.title.value)));
        setIsFilterDate(false);        
        setIsFilterScore(false);
        setIsFilterTags(false);
        setIsSearch(false);     
        setIsFilterCreator(false);
        setIsFilterLocation(false);
    };

    const handleSortUp = () => {
        setFilteredUseCases([...filteredUseCases].sort((a, b) => b.title.value.localeCompare(a.title.value)));
        setIsFilterDate(false);        
        setIsFilterScore(false);
        setIsFilterTags(false);
        setIsSearch(false);       
        setIsFilterCreator(false);  
        setIsFilterLocation(false);
    };

    const handleFilterDate = () => {
        setIsFilterDate(!isFilterDate);        
        setIsFilterScore(false);
        setIsFilterTags(false);
        setIsSearch(false); 
        setIsFilterCreator(false);
        setIsFilterLocation(false);
    };

    const handleFilterScore = () => {
        setIsFilterScore(!isFilterScore);
        setIsFilterDate(false);
        setIsFilterTags(false);
        setIsSearch(false); 
        setIsFilterCreator(false);
        setIsFilterLocation(false);
    };

    const handleFilterKeywords = () => {
        setIsFilterTags(!isFilterTags);
        setIsFilterDate(false);
        setIsFilterScore(false);
        setIsSearch(false); 
        setIsFilterCreator(false);
        setIsFilterLocation(false);
    };

    const handleFilterDateChange = (e) => {
        //console.log('Date:', e.target.value);
        setFilterDate(e.target.value);
    };

    const handleReset = () => {
        setSelectedKeyword('');
        setFilterKeyword('');
        setFilterDate('');
        setIsFilterTags(false);
        setIsFilterDate(false);
        setIsFilterScore(false);
        setIsFilterCreator(false);
        setIsSearch(false);
        setIsFilter(false);
        setIsFilterLocation(false);

        setSelectedCity('');
        setSelectedCountry('');
        setSelectedContinent('');

        setSelectedTags([]);
        setSelectedCreator('');
    };

    // Toggle between card and accordion view as well as user view

    const handleToggleView = () => {
        setViewMode(viewMode === 'card' ? 'accordion' : 'card');

        const newItemsPerPage = (viewMode === 'card') ? maxItemsCard : maxItemsAccordion;   
        const totalPages = Math.ceil(useCases.length / newItemsPerPage);
        setCurrentPage((prevPage) => (prevPage > totalPages ? totalPages : prevPage));
    };

    const handleToggleUserCases = () => {
        setShowUserCases(prevShow => !prevShow);
    };

    const handleInspectUseCase = (id) => {
        setIsFilter(false);
        //setSelectedUseCaseId(id);

        setSelectedUseCase(useCases.find(useCase => useCase.id === id));

        setViewMode('inspect');
    };

    const handleUseAsTemplate = (id) => {
        let template = useCases.find(useCase => useCase.id === id);     
        //setSelectedUseCaseId(id);
        navigate('/Create', { state: { template } });
    };

    // Handle delete use case, filter the use case to be deleted
    const handleDeleteUseCase = (id) => {
        setDeleteUseCase(useCases.filter(useCase => useCase.id === id));
        setIsDelete(true);
    };

    // Update the use case data in the database
    const handleSubmitDelete = async () => {
        if (!deleteUseCase) return;
        
        try {
        await deleteUseCaseData(deleteUseCase[0].id);

        console.log('Use case data updated successfully!');      
        } catch (error) {
        console.error('Error updating use case data:', error);
        }     
        
        setIsDelete(false);
    };

    const handleCloseDeleteModal = () => {
        setIsDelete(false);
    };

    const handleCheckboxChange = (id) => {
        setSelectedUseCases((prevSelected) =>
        prevSelected.includes(id)
            ? prevSelected.filter((useCaseId) => useCaseId !== id)
            : [...prevSelected, id]
        );
    };

    const filteredItems = currentItems.filter(useCase => {
        const matchesKeyword = selectedKeyword === '' || useCase.tags.includes(selectedKeyword);
        const matchesSearchKeyword = filterKeyword === '' || useCase.name.toLowerCase().includes(filterKeyword.toLowerCase());
        const createdDate = formatTimestamp(useCase.created);
        const matchesDate = filterDate === '' || createdDate === filterDate;
        return matchesKeyword && matchesSearchKeyword && matchesDate;
    });
      
    return (
        <div>
            <Row>
                {/* Headline */}
                <Col md={6}>
                    <div>
                        <h3 className="text-start">Modeled Use Cases</h3>
                        <h6 className="text-start">Look up, compare or use as template</h6>
                    </div>
                </Col>  
                <Col md={6} className="d-flex justify-content-end align-items-center">
                    {/* toolbar */}
                    <div className="d-flex justify-content-end">
                        {viewMode !=='inspect' && (
                            <>
                            <Form>
                                <Form.Group>
                                    <Form.Control
                                        style={{ maxWidth: '400px' }}
                                        //size='sm'
                                        type="text"
                                        placeholder="Search use case"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                handleSearch(e);
                                            }
                                        }}
                                        onChange={e => {
                                            handleSearch(e);
                                        }}
                                    />                    
                                </Form.Group>
                            </Form>
                            
                            <OverlayTrigger placement="top" overlay={<Tooltip>Advanced filter options</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleFilter}>
                                    <i className="bi bi-funnel"></i> Filter
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={<Tooltip>Create a new use case</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleCreateMode}>
                                    <i className="bi bi-pencil-square"></i>
                                </Button>
                            </OverlayTrigger>

                            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>

                            <OverlayTrigger placement="top" overlay={<Tooltip>Import use cases</Tooltip>}>   
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                                    <i className="bi bi-upload"></i>
                                </Button>
                            </OverlayTrigger>
                            </>
                        )}      

                        <OverlayTrigger placement="top" overlay={<Tooltip>Export selected use cases</Tooltip>}>
                            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                                <i className="bi bi-download"></i>
                            </Button>
                        </OverlayTrigger>            

                        <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>                        
                        {(viewMode === 'card') && (
                            <>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Show list view</Tooltip>}>
                                <Button variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={handleToggleView}>
                                    <i className="bi bi-view-list"></i> 
                                </Button>
                            </OverlayTrigger>
                            </>
                        )}

                        {(viewMode === 'accordion') && (
                            <>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Show card view</Tooltip>}>
                            <Button variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={handleToggleView}>
                                <i className="bi bi-view-stacked"></i>
                            </Button>
                            </OverlayTrigger>
                            </>
                        )}

                        {(viewMode === 'inspect') && (
                            <Button variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={() => setViewMode('card')}>
                                <i className="bi bi-arrow-left"></i>
                            </Button>
                        )}

                        {viewMode !== 'inspect' && (
                        <>
                            {showUserCases ? (
                                <>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Show all use cases</Tooltip>}>
                                        <Button variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={handleToggleUserCases}>
                                            <i className="bi bi-boxes"></i>
                                        </Button>
                                    </OverlayTrigger>    
                                </>
                            ) : (
                                <>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Show own use cases</Tooltip>}>
                                        <Button variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={handleToggleUserCases}>
                                            <i className="bi bi-box"></i>
                                        </Button>
                                    </OverlayTrigger>
                                </>
                            )}
                        </>
                        )}
                    </div>
                </Col>
            </Row>
            {/* use cases */}
            <Row>
                <Col md={1}></Col>
                <Col md={10} style={{ textAlign: 'left' }}>

                    {/* Filter use cases by key word, created, etc.; use clickable badges for this  */}
                    {isFilter && (
                    <>
                        <div className="text-center mb-4">

                            <OverlayTrigger placement="top" overlay={<Tooltip>Filter by Keywords</Tooltip>}>
                                <Button className='btn-sm' variant="outline-secondary" style={ { marginRight:'10px' } } onClick={handleFilterKeywords}>
                                <i className="bi bi-tags"></i>
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={<Tooltip>Sort alphabetically down</Tooltip>}>
                                <Button className='btn-sm' style={{ marginRight: '10px' }} variant="outline-secondary" onClick={handleSortDown}>
                                    <i className="bi bi-sort-alpha-down"></i>
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={<Tooltip>Sort alphabetically up</Tooltip>}>
                                <Button className='btn-sm' style={{ marginRight: '10px' }} variant="outline-secondary" onClick={handleSortUp}>
                                    <i className="bi bi-sort-alpha-up"></i>
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={<Tooltip>Sort by date</Tooltip>}>
                                <Button className='btn-sm' variant="outline-secondary" style={ { marginRight:'10px' } } onClick={handleFilterDate}>
                                    <i className="bi bi-calendar3"></i>
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={<Tooltip>Sort by score</Tooltip>}>
                                <Button className='btn-sm' variant="outline-secondary" style={ { marginRight:'10px' } } onClick={handleFilterScore}>
                                <i className="bi bi-123"></i>
                                </Button>
                            </OverlayTrigger>

                            {!showUserCases && (
                            <OverlayTrigger placement="top" overlay={<Tooltip>Filter by Creator</Tooltip>}>
                                <Button className='btn-sm' variant="outline-secondary" style={ { marginRight:'10px' } } onClick={handleFilterCreator}>
                                <i className="bi bi-person-check"></i>
                                </Button>
                            </OverlayTrigger>
                            )}

                            <OverlayTrigger placement="top" overlay={<Tooltip>Filter by Location</Tooltip>}>
                                <Button className='btn-sm' variant="outline-secondary" style={ { marginRight:'10px' } } onClick={handleFilterLocation}>
                                <i className="bi bi-geo-alt"></i>
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={<Tooltip>Reset filters</Tooltip>}>
                                <Button className='btn-sm' variant="outline-secondary" style={ { marginRight:'10px' } } onClick={handleReset}>
                                    <i className="bi bi-x"></i>
                                </Button>
                            </OverlayTrigger>                            
                        </div>
                    </>
                    )}

                    {isFilterTags && (
                    <>
                        <div className="text-center">

                            {/* Multi-select Tags */}
                            <div className="mt-3">
                                {uniqueKeywords.map((keyword, index) => (
                                    <Badge
                                        key={index}
                                        bg={selectedTags.includes(keyword) ? "primary" : "light"}
                                        text={selectedTags.includes(keyword) ? "white" : "dark"}
                                        style={{ marginRight: '10px', cursor: 'pointer' }}
                                        onClick={() => handleTagSelection(keyword)}
                                    >
                                        {keyword}
                                    </Badge>
                                ))}
                            </div>
                        
                        </div>
                    </>
                    )}

                    <div className="d-flex justify-content-center mb-3">
                        <Form className="d-flex align-items-center">

                            {isFilterDate && (
                            <>
                                <Form.Group controlId="filterDate" className="me-3">
                                    <Form.Control
                                        type="date"
                                        value={filterDate}
                                        onChange={handleFilterDateChange}
                                        style={{ width: '200px' }}
                                    />
                                </Form.Group>
                            </>
                            )}

                            {isFilterScore && (
                            <>
                                <Form.Group controlId="filterScore" className="me-3">
                                    <Form.Control
                                    disabled
                                    type="text"
                                    placeholder="Enter score"
                                    style={{ width: '200px' }} // Set a fixed width
                                    />
                                </Form.Group>
                            </>
                            )}

                            {isFilterCreator && (
                            <>
                                <Form.Select className="btn-sm" style={{ display: 'inline-block', width: '200px' }} value={selectedCreator} onChange={handleCreatorChange}>
                                    <option value="">All Creators</option>
                                    {uniqueCreator.map((creator, index) => (
                                        <option key={index} value={creator}>{creator}</option>
                                    ))}
                                </Form.Select>
                            </>
                            )}

                            {isFilterLocation && (
                            <>
                                <Form.Group controlId="filterCity" className="me-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="City"
                                        style={{ width: '200px' }}
                                        onChange={handleCityChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="filterCountry" className="me-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Country"
                                        style={{ width: '200px' }}
                                        onChange={handleCountryChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="filterContinent" className="me-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Continent"
                                        style={{ width: '200px' }}
                                        onChange={handleContinentChange}
                                    />
                                </Form.Group>
                            </>
                            )}

                        </Form>
                    </div>
                
                    {viewMode === 'card' && (
                        renderCardView(
                            filteredItems, 
                            selectedUseCases,
                            handleCheckboxChange, 
                            handleUseAsTemplate, 
                            getUserID, 
                            handleDeleteUseCase, 
                            handleInspectUseCase
                        )
                    )}

                    {viewMode === 'accordion' && (
                        renderAccordionView(
                            filteredItems, 
                            selectedUseCases, 
                            handleCheckboxChange, 
                            handleInspectUseCase, 
                            handleUseAsTemplate, 
                            getUserID, 
                            handleDeleteUseCase
                        )
                    )}

                    {viewMode === 'inspect' && (
                        //<InspectUC data={useCases[0]} /> // setData={setData} useCaseDetails={useCaseDetails}
                        //<AnalyticsUCDashboard data={useCases.find(useCase => useCase.id === selectedUseCaseId)} />
                        <EcoUCDashboard
                            mode={'Dashboard'}
                            data={useCases} 
                            selectedUseCase={selectedUseCase} 
                            setSelectedUseCase={setSelectedUseCase}
                            comparisonType={comparisonType}
                            setComparisonType={setComparisonType}
                            selectedComparisonUseCases={selectedComparisonUseCases}
                            setSelectedComparisonUseCases={setSelectedComparisonUseCases}
                        />
                    )}

                </Col>
                <Col md={1}></Col>
                {/* pagination */}
                {viewMode !== 'inspect' && (
                <Row>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">

                                {/* Previous Button */}
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        onClick={() => paginate(currentPage - 1)}
                                        className="page-link"
                                        style={{
                                        textDecoration: 'none',
                                        cursor: 'pointer'
                                        }}
                                        disabled={currentPage === 1}
                                    >
                                        &laquo; Previous
                                    </button>
                                </li>

                                {/* Page Numbers */}
                                {pageNumbers.map(number => (
                                <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                                    <button
                                        onClick={() => paginate(number)}
                                        className="page-link"
                                        style={{                         
                                            textDecoration: 'none',
                                            cursor: 'pointer'
                                        }}
                                        >
                                        {number}
                                    </button>
                                </li>
                                ))}

                                {/* Next Button */}
                                <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                                    <button
                                        onClick={() => paginate(currentPage + 1)}
                                        className="page-link"
                                        style={{
                                        textDecoration: 'none',
                                        cursor: 'pointer'
                                        }}
                                        disabled={currentPage === pageNumbers.length}
                                    >
                                        Next &raquo;
                                    </button>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </Row>
                )}
            </Row>            
            {/* show modal to save the modeled use case */}
            {isDelete && (
                <UCModalDelete
                    onClose={handleCloseDeleteModal}
                    uc_name={deleteUseCase[0].title.value}
                    handleDelete={handleSubmitDelete}
                />
            )}
            {/* Modal for Create Mode */}
            {showModalCreateMode && (
                <ModalCreateMode 
                show={showModalCreateMode} 
                onHide={() => setShowModalCreateMode(false)}         
                />  
            )}
        </div>
    );
};

export default RepositoryView;
