/**
 * This modal allows the user to select the mode to create a use case
 * 
 * Description: The modes are create manually, create from template, and create using the UCM copilot
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-27
 * 
 */

import React, { useState } from 'react';
import { Button, Modal, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

/**
 * ModalCreateMode
 * 
 * @param {*} param0
 * @returns 
 */
const ModalCreateMode = ({ show, onHide }) => {

    const [selectedCard, setSelectedCard] = useState('useUCMCopilot');
    const navigate = useNavigate();

    const navigateRepository = () => {
        navigate('/Repository');
    };

    const navigateSemModels = () => {
        navigate('/KB');
        onHide();
    };

    const handleCardClick = (card) => {
        setSelectedCard(card);
    };

    const handleConfirm = () => {
        if (selectedCard === 'createManually') {
            navigate('/Create', { state: 'manually' });
        } else if (selectedCard === 'createFromTemplate') {
            navigateRepository();
        } else if (selectedCard === 'useUCMCopilot') {
            //navigateCockpit('copilot');
            navigate('/Create', { state: 'copilot' });
        }
        onHide();
    };

    const getCardContent = (card) => {
        switch (card) {
            case 'createManually':
                return "Manually create a use case with detailed specifications.";
            case 'createFromTemplate':
                return "Start with a template from the repository to quickly create a use case.";
            case 'useUCMCopilot':
                return "Get started with entering just one prompt; UCM applies models your individual use case.";
            default:
                return "";
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Use Case Creator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>
                    The UCM creator allows you to create use cases for your projects. 
                    You can create a use case manually, use a template, or use the UCM Copilot to create a use case.
                </h6>
                <h6 style={{ marginTop:'20px'}}><b>Select use case creator mode</b></h6>     
                <Row align='center' style={{ marginTop: '20px' }}>
                    {/* Copilot */}
                    <Col md={12}>
                        <Card 
                            className={`text-center mb-3 ${selectedCard === 'useUCMCopilot' ? 'bg-primary text-white' : ''}`} 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => handleCardClick('useUCMCopilot')}
                        >
                            <Row>
                                <Col>
                                    <Card.Body>
                                        <Card.Title>Use UCM Copilot</Card.Title>
                                        <i className="bi bi-lightbulb" style={{ fontSize: '2rem' }}></i>
                                    </Card.Body>
                                </Col>
                                {selectedCard === 'useUCMCopilot' && (
                                <Col>
                                    <Card.Body align='right'>
                                        <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                            <i className="bi bi-info-circle"></i> {getCardContent(selectedCard)}
                                        </Card.Text>
                                        <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Optional: in the settings you can generate or import custom domain models (Explorer Module)</Tooltip>}>
                                        <Button variant="light" size="sm" onClick={navigateSemModels}>
                                            <i className="bi bi-gear"></i>
                                        </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Use the UCM Co-Pilot to create a use case</Tooltip>}>
                                        {/* Start analysis button */}
                                        <Button 
                                            style={{ marginLeft: '5px' }}
                                            variant="light" 
                                            size="sm"
                                            onClick={() => {
                                                handleConfirm();
                                                onHide();
                                            }} 
                                            disabled={!selectedCard}
                                            >
                                            <i className="bi bi-cpu"></i> Let's Go!
                                        </Button>
                                        </OverlayTrigger>
                                    </Card.Body>
                                </Col>
                                )}
                            </Row>
                        </Card>
                    </Col>
                    {/* Create manually card */}
                    <Col md={12}>
                        <Card 
                            className={`text-center mb-3 ${selectedCard === 'createManually' ? 'bg-primary text-white' : ''}`} 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => handleCardClick('createManually')}
                        >
                            <Row>
                                <Col>  
                                    <Card.Body>
                                    <Card.Title>Create Manually</Card.Title>
                                    <i className="bi bi-pencil" style={{ fontSize: '2rem' }}></i>
                                    </Card.Body>
                                </Col>
                                {selectedCard === 'createManually' && (
                                <Col>
                                    <Card.Body align='right'>
                                        <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                            <i className="bi bi-info-circle"></i> {getCardContent(selectedCard)}
                                        </Card.Text>
                                        <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Create a use case manually</Tooltip>}>
                                        <Button 
                                            variant="light" 
                                            size="sm"
                                            onClick={() => {
                                                handleConfirm();
                                                onHide();
                                            }} 
                                            disabled={!selectedCard}
                                            >
                                            <i className="bi bi-cpu"></i> Let's Go!
                                        </Button>
                                        </OverlayTrigger>
                                    </Card.Body>
                                </Col>
                                )}
                            </Row>
                        </Card>
                    </Col>
                    {/* Create from template */}
                    <Col md={12}>
                        <Card 
                            className={`text-center mb-3 ${selectedCard === 'createFromTemplate' ? 'bg-primary text-white' : ''}`} 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => handleCardClick('createFromTemplate')}
                        >
                            <Row>
                                <Col>
                                    <Card.Body>
                                    <Card.Title>Start with Template</Card.Title>
                                    <i className="bi bi-database" style={{ fontSize: '2rem' }}></i>
                                    </Card.Body>
                                </Col>
                                {selectedCard === 'createFromTemplate' && (
                                <Col>
                                    <Card.Body align='right'>
                                        <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                            <i className="bi bi-info-circle"></i> {getCardContent(selectedCard)}
                                        </Card.Text>
                                        <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Select an existing use case as a template</Tooltip>}>
                                        {/* Start analysis button */}
                                        <Button 
                                            style={{ marginLeft: '5px' }}
                                            variant="light" 
                                            size="sm"
                                            onClick={() => {
                                                handleConfirm();
                                                onHide();
                                            }} 
                                            disabled={!selectedCard}
                                            >
                                            <i className="bi bi-cpu"></i> Let's Go!
                                        </Button>
                                        </OverlayTrigger>
                                    </Card.Body>
                                </Col>
                                )}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onHide}>
                <i className="bi bi-arrow-left"></i> Go back
                </Button>
                {/* Start analysis button
                <Button 
                    variant="success" 
                    onClick={() => {
                        handleConfirm();
                        onHide();
                    }} 
                    disabled={!selectedCard}
                    >
                    <i className="bi bi-cpu"></i> Let's Go!
                </Button>
                */}
            </Modal.Footer>
        </Modal>
    );
};

export default ModalCreateMode;