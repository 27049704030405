import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { expandAllItems, collapseAllItems } from '../onto_tree_handling/expansion';
import { expandTreeTowardsConcept } from '../onto_tree_handling/find_concept';
import { addConcept } from '../onto_tree_handling/new_concept';

import { DroppableAreaDetailed, DroppableAreaFoldable } from '../component_handling/render_area';
import { renderConceptBadges } from '../component_handling/render_concepts';

import ConceptModal from '../component_handling/modal_concept';

import { getCurrentDate } from '../utils/utils_date';
import { UserInformation } from '../auth_mgmt/auth_handling';

const Components = ({ data, setData, useCaseDetails, sidebarItems, setSidebarItems, onto_name }) => {

    const [search, setSearch] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [foundConcept, setFoundConcept] = useState(false);

    const [compArchLabels, setCompArchLabels] = useState({
        // Operations
        'Group_Operations': 'Operations: Systems, Services, and Tools',
        'Group_Operations_Management': 'Operations Management',
        'Group_Operations_Analytics': 'Operations Analytics',
        'Group_Operations_Platform': 'Operations Platform',
        // Producer
        'Group_Producer': 'Producer',
        'Group_Producer_Analytics': 'Producer Analytics',
        'Group_Producer_Management': 'Producer Management',
        'Group_Producer_Platform': 'Producer Platform',
        // Consumer
        'Group_Consumer': 'Consumer',
        'Group_Consumer_Analytics': 'Consumer Analytics',
        'Group_Consumer_Delivery': 'Consumer Delivery',
        'Group_Consumer_Platform': 'Consumer Platform',
        // Technical
        'Group_Technical': 'Technical Layer',
        // - initialized as per semantic model
        // Regulation
        'Group_Regulator': 'Regulator',
        'Group_Directives': 'Directives',
        'Group_Reference_Cases': 'Reference Cases',
        'Group_Standards': 'Standards',
    });
    const [isFoldedView, setIsFoldedView] = useState(true);
    const [allExpanded, setAllExpanded] = useState(true);

    const [isDetailedView, setIsDetailedView] = useState(false);    

    // Modal handling to add new concepts
    const [triggerAddConcept, setTriggerAddConcept] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [inputConcept, setInputConcept] = useState('');
    const [newConcept, setNewConcept] = useState('');
    const [hasLinkage, setHasLinkage] = useState(false);

    const [droppedItems, setDroppedItems] = useState({
        // Operations
        'Operations: Systems, Services, and Tools': [],
        'Operations Management': [],
        'Operations Analytics': [],
        'Operations Platform': [],
        // Producer
        'Producer': [],
        'Producer Analytics': [],
        'Producer Management': [],
        'Producer Platform': [],
        // Consumer
        'Consumer': [],
        'Consumer Analytics': [],
        'Consumer Delivery': [],
        'Consumer Platform': [],
        // Technical layer
        'Applications / Interfaces (proprietary, third-party, partially distributed)': [],
        'Platform and Cloud (proprietary, third-party, partially distributed)': [],
        'ADS: Automated Driving System': [],
        'Hardware (Vehicle, Infrastructure)': [],
        'AI-Middleware and Edge (Devices)': [],    
        // Regulator
        'Directives': [],
        'Reference Cases': [],
        'Standards': [],                
    });

    // initialize the dropped items
    useEffect(() => {
        const initialDroppedItems = {};
    
        data.components.value.forEach(componentCategory => {
            componentCategory.components.forEach(subComponent => {
                initialDroppedItems[subComponent.description] = subComponent.items || [];
            });
        });
    
        setDroppedItems(initialDroppedItems);
    }, [data.components]);

    // update the data object with the use case details
    /*
    useEffect(() => {

        if (!useCaseDetails || useCaseDetails.length === 0) return;
    
        const categories = [
            'Operations Management',
            'Operations Analytics',
            'Operations Platform',
            'Producer Analytics',
            'Producer Management',
            'Producer Platform',
            'Consumer Analytics',
            'Consumer Delivery',
            'Consumer Platform',
            'Applications / Interfaces (proprietary, third-party, partially distributed)',
            'Platform and Cloud (proprietary, third-party, partially distributed)',
            'ADS: Automated Driving System',
            'AI-Middleware and Edge (Devices)',
            'Hardware (Vehicle, Infrastructure)',
            'Directives',
            'Reference Cases',
            'Standards'
        ];
    
        setDroppedItems(prevDroppedItems => {
            const newDroppedItems = { ...prevDroppedItems };
    
            // Ensure each category is initialized to an empty array if undefined
            categories.forEach(category => {
                if (!newDroppedItems[category]) {
                    newDroppedItems[category] = [];
                }
            });
    
            // Fill the fields from the use case details
            categories.forEach((category, index) => {
                const useCaseIndex = 28 + index;
                if (useCaseDetails[useCaseIndex]) {
                    useCaseDetails[useCaseIndex].response.split(',').forEach(item => {
                        const trimmedItem = item.trim();
                        if (!newDroppedItems[category].includes(trimmedItem)) {
                            newDroppedItems[category].push(trimmedItem);
                        }
                    });
                }
            });
    
            return newDroppedItems;
        });

        setData(...data, droppedItems);

    }, [useCaseDetails, droppedItems, data, setData]);
    */

    // assign component architecture labels
    useEffect(() => {

        if (onto_name === 'PEAMONTO')
        {
            // add technical layer
            setCompArchLabels(
                prevLabels => ({
                    ...prevLabels,
                    'Group_Applications': 'Applications / Interfaces (proprietary, third-party, partially distributed)',
                    'Group_Platform': 'Platform and Cloud (proprietary, third-party, partially distributed)',
                    'Group_Control': 'ADS: Automated Driving System',
                    'Group_Middleware': 'AI-Middleware and Edge (Devices)',
                    'Group_Hardware': 'Hardware (Vehicle, Infrastructure)',
                })
            );
        } else {
            // add generic technical layer
            setCompArchLabels(
                prevLabels => ({
                    ...prevLabels,
                    'Group_Applications': 'Applications / Interfaces (proprietary, third-party, partially distributed)',
                    'Group_Platform': 'Platform and Cloud (proprietary, third-party, partially distributed)',
                    'Group_Control': 'Control Layer (Central Functionality)',
                    'Group_Middleware': 'AI-Middleware and Edge (Devices)',
                    'Group_Hardware': 'Hardware (Infrastructure, Computing)',
                })
            )
        }

    }, [setCompArchLabels, onto_name]);

    // prompt and edit mode: update the data object with the dropped items
    useEffect(() => {

        if (!data.components.value || data.components.value.length === 0) return;
    
        // Initialize an object to hold the new dropped items
        const newDroppedItems = {};
    
        // Iterate over each component in the data
        data.components.value.forEach(component => {
            const categoryName = component.category;
        
            // Ensure the category is initialized in newDroppedItems
            if (!newDroppedItems[categoryName]) {
                newDroppedItems[categoryName] = [];
            }
        
            // Iterate over each subcomponent in the component
            component.components.forEach(subComponent => {
                // Use the subComponent description as the key
                const subComponentDescription = subComponent.description;
        
                // Ensure the subcomponent category is initialized in newDroppedItems
                if (!newDroppedItems[subComponentDescription]) {
                newDroppedItems[subComponentDescription] = [];
                }
        
                // Iterate over each item in the subcomponent
                subComponent.items.forEach(item => {
                if (!newDroppedItems[subComponentDescription].includes(item)) {
                    newDroppedItems[subComponentDescription].push(item);
                }
                });
            });
        });
    
        // Set the new dropped items
        setDroppedItems(prevDroppedItems => {
          if (JSON.stringify(prevDroppedItems) !== JSON.stringify(newDroppedItems)) {
            return newDroppedItems;
          }
          return prevDroppedItems;
        });
    
    }, [data, setDroppedItems]);
    
    const expandAll = () => {
        const updatedSidebarItems = expandAllItems(sidebarItems);
        setSidebarItems(updatedSidebarItems);
        setIsExpanded(true);
    }

    const collapseAll = () => {
        const updatedSidebarItems = collapseAllItems(sidebarItems);
        updatedSidebarItems[0].collapsed = false;
        setSidebarItems(updatedSidebarItems);
        setIsExpanded(false);
    }

    const handleSearch = () => {
        setSearch(!search);
    }

    const handleSearchConcept = (e) => {
        e.preventDefault();
        const searchValue = e.target.value;
        const found = expandTreeTowardsConcept(searchValue, sidebarItems, setSidebarItems);
        setFoundConcept(found);
        if (!found) {
            setTriggerAddConcept(true);
        } else {
            setInputConcept(searchValue);
        }
    }

    const handleModalAddConcept = () => {
        setInputConcept(inputConcept);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const addNew = (e) => {
        e.preventDefault();
        const newConceptTitle = e.target.newConcept.value;
        const parentConceptTitle = e.target.parentConcept.value;
        if (newConceptTitle === '') {
            return;
        }
        setNewConcept(newConceptTitle);
        const found = addConcept(newConceptTitle, parentConceptTitle, sidebarItems, setSidebarItems)
        setHasLinkage(found);
        e.target.newConcept.value = '';
        e.target.parentConcept.value = '';
        if (hasLinkage) {
            handleCloseModal();
        }
    }    

    /*
    const handleDrop = (item, sectionName) => {

        setDroppedItems(prev => {
          // Find the source section and remove the item from it
          const sourceSection = Object.keys(prev).find(section =>
            prev[section].includes(item.title)
          );
      
          if (!sourceSection) {
            // If the item is not found in any section, just add it to the target section
            // As it was dragged from the sidebar
            return {
                ...prev,
                [sectionName]: [...prev[sectionName], item.title],
            }
          }
      
          // Remove the item from the source section
          const newSourceItems = prev[sourceSection].filter(i => i !== item.title);
          
          // Add the item to the target section
          const newTargetItems = [...prev[sectionName], item.title];
      
          return {
            ...prev,
            [sourceSection]: newSourceItems,
            [sectionName]: newTargetItems,
          };
        });
    };
    */

    const handleDrop = (item, sectionName) => {

        const sourceSection = Object.keys(droppedItems).find(section => droppedItems[section].includes(item.title));

        setDroppedItems(prev => {
            //const sourceSection = Object.keys(prev).find(section => prev[section].includes(item.title));
    
            if (!sourceSection) {
                return {
                    ...prev,
                    [sectionName]: [...prev[sectionName], item.title],
                };
            }
    
            const newSourceItems = prev[sourceSection].filter(i => i !== item.title);
            const newTargetItems = [...prev[sectionName], item.title];
    
            return {
                ...prev,
                [sourceSection]: newSourceItems,
                [sectionName]: newTargetItems,
            };
        });
    
        // Update useCaseData components
        setData(prevData => {
            const updatedComponents = prevData.components.value.map(componentCategory => {
                if (componentCategory.components.some(subComponent => subComponent.description === sectionName)) {
                    return {
                        ...componentCategory,
                        components: componentCategory.components.map(subComponent => {
                            if (subComponent.description === sectionName) {
                                return {
                                    ...subComponent,
                                    items: [...subComponent.items, item.title],
                                };
                            }
                            if (sourceSection && subComponent.description === sourceSection) {
                                return {
                                    ...subComponent,
                                    items: subComponent.items.filter(i => i !== item.title),
                                };
                            }
                            return subComponent;
                        }),
                    };
                }
                return componentCategory;
            });
    
            return {
                ...prevData,
                components: {
                    ...prevData.components,
                    value: updatedComponents,
                    modified: true,
                    updated_timestamp: getCurrentDate(),
                    updated_by: UserInformation().displayName,
                    uid: UserInformation().uid,
                    version: prevData.components.version + 1,
                },
            };
        });
    };
    

    const handleView = () => {
        setIsFoldedView(!isFoldedView);
        setIsDetailedView(!isDetailedView);
    }

    const handleAddElement = (sectionName) => {

        const newElementTitle = prompt("Enter the title of the new element:");

        if (newElementTitle) {
            setDroppedItems(prev => ({
                ...prev,
                [sectionName]: [...prev[sectionName], newElementTitle],
            }));

            // Update the data object as well
            /*
            setData(prevData => {
                // Find the corresponding category in the data object
                const updatedComponents = prevData.components.map(componentCategory => {
                    if (componentCategory.category === sectionName || 
                        componentCategory.components.some(subComponent => subComponent.description === sectionName)) {
                        return {
                            ...componentCategory,
                            components: componentCategory.components.map(subComponent => {
                                if (subComponent.description === sectionName) {
                                    return {
                                        ...subComponent,
                                        items: [...subComponent.items, newElementTitle],
                                    };
                                }
                                return subComponent;
                            }),
                        };
                    }
                    return componentCategory;
                });

                return {
                    ...prevData,
                    components: updatedComponents,
                };
            });
            */         

            // Update useCaseData components
            setData(prevData => {
                const updatedComponents = prevData.components.value.map(componentCategory => {
                    if (componentCategory.components.some(subComponent => subComponent.description === sectionName)) {
                        return {
                            ...componentCategory,
                            components: componentCategory.components.map(subComponent => {
                                if (subComponent.description === sectionName) {
                                    return {
                                        ...subComponent,
                                        items: [...subComponent.items, newElementTitle],
                                    };
                                }
                                return subComponent;
                            }),
                        };
                    }
                    return componentCategory;
                });

                return {
                    ...prevData,
                    components: {
                        ...prevData.components,
                        value: updatedComponents,
                        modified: true,
                        updated_timestamp: getCurrentDate(),
                        updated_by: UserInformation().displayName,
                        uid: UserInformation().uid,
                        version: prevData.components.version + 1,
                    },
                };
            });

        }
    };

    const handleDeleteElement = (sectionName, itemTitle) => {

        setDroppedItems(prev => {
            // Ensure the sectionName exists in prev
            if (!prev[sectionName]) {
                console.error(`Section ${sectionName} does not exist in state.`);
                return prev;
            }
            return {
                ...prev,
                [sectionName]: prev[sectionName].filter(title => title !== itemTitle),
            };
        });

        // update the data object as well
        /*
        setData({
            ...data,
            components: data.components.value.map(component => {
                return {
                    ...component,
                    components: component.components.map(subComponent => {
                        return {
                            ...subComponent,
                            items: subComponent.items.filter(item => item !== itemTitle),
                        };
                    }),
                };
            }),
        });
        */

        // Update the data object
        setData(prevData => {
            // Find and update the corresponding component category and subcategory
            const updatedComponents = prevData.components.value.map(componentCategory => {
                return {
                    ...componentCategory,
                    components: componentCategory.components.map(subComponent => {
                        if (subComponent.description === sectionName) {
                            return {
                                ...subComponent,
                                items: subComponent.items.filter(item => item !== itemTitle),
                            };
                        }
                        return subComponent;
                    }),
                };
            });

            return {
                ...prevData,
                components: {
                    ...prevData.components,
                    value: updatedComponents,
                    modified: true,
                    updated_timestamp: getCurrentDate(),
                    updated_by: UserInformation().displayName,
                    uid: UserInformation().uid,
                    version: prevData.components.version + 1,  // Increment the version
                }
            };
        });

    };

    // Expand all Foldable Areas
    const handleExpandArea = () => {
        setAllExpanded(true);
    }

    // Collapse all Foldable Areas
    const handleCollapseArea = () => {
        setAllExpanded(false);
    }       

    //console.log(sidebarItems)

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                <Row>
                    {/* Headline and Toolbar */}
                    <Col md={3}>
                        <div>
                            <h3 className="text-start">Identify Components</h3>
                            <h6 className="text-start">Look up, adjust or add manually</h6>
                        </div>
                    </Col>
                    {/* Toolbar */}
                    <Col md={9} className="d-flex justify-content-end align-items-center">
                        <OverlayTrigger placement="top" overlay={<Tooltip>Look up a dedicated component in the technical landscape</Tooltip>}>
                            <Form>
                                <Form.Group>
                                    <Form.Control
                                        disabled    
                                        style={{ maxWidth: '200px' }}
                                        // size='sm'
                                        type="text"
                                        placeholder="Search component"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                // handleSearch(e);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Form>
                        </OverlayTrigger>   
                        
                        <OverlayTrigger placement="top" overlay={<Tooltip>Filter components</Tooltip>}>
                            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                                <i className="bi bi-funnel"></i>
                            </Button>
                        </OverlayTrigger>        

                        <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div> 

                        {isDetailedView && (
                            <OverlayTrigger placement="top" overlay={<Tooltip>Folded View</Tooltip>}>
                            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleView}>
                                <i className="bi bi-archive"></i>                       
                            </Button>
                            </OverlayTrigger>
                        )}
                        {isFoldedView && (
                            <>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Detailed View</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleView}>
                                    <i className="bi bi-card-list"></i>                            
                                </Button>
                            </OverlayTrigger>
                           
                            <OverlayTrigger placement="top" overlay={<Tooltip>Expand all concepts</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleExpandArea}>
                                    <i className="bi bi-arrows-expand"></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Collapse all concepts</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleCollapseArea}>
                                    <i className="bi bi-arrows-collapse"></i>
                                </Button>
                            </OverlayTrigger>
                            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>
                            </>
                        )}               

                        <OverlayTrigger placement="top" overlay={<Tooltip>List View</Tooltip>}>
                            <Button variant="outline-secondary" style={{ marginLeft: '10px' }} disabled>
                            <i className="bi bi-list-nested"></i>
                            </Button>
                        </OverlayTrigger>      

                        <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>

                        <OverlayTrigger placement="top" overlay={<Tooltip>Download actor landscape</Tooltip>}>
                            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                                <i className="bi bi-download"></i>
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger placement="top" overlay={<Tooltip>Upload actor landscape</Tooltip>}>
                            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                                <i className="bi bi-upload"></i>
                            </Button>
                        </OverlayTrigger>         

                    </Col>
                </Row>
                <Row>
                    {/* Sidebar */}
                    {(!sidebarItems || sidebarItems.length !== 0) &&
                    <Col md={3} style={{ paddingRight: '30px', backgroundBlendMode: 'gray' }}> {/* borderRight: '0.5px solid #ccc', */}
                        <hr />
                        <div className="sidebar">
                            {/* Toolbar */}
                            <Row>
                                <h6 className="text-start">{onto_name} Elements | Classes & Concepts</h6>
                                <div className="text-start">
                                    {isExpanded ? (
                                        <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={collapseAll}>
                                            <i className="bi bi-arrows-collapse"></i> Collapse All
                                        </Button>                                    
                                    ) : (
                                        <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={expandAll}>
                                        <i className="bi bi-arrows-expand"></i> Expand All
                                        </Button>
                                    )}                                    
                                    <Button variant="outline-primary" size="sm" onClick={handleSearch}>
                                        <i className="bi bi-search"></i> Search Concept
                                    </Button>
                                    <div style={{ marginTop: '10px' }}>
                                        {search && (
                                            <Form>
                                                <Form.Group className="mb-3 d-flex align-items-center">
                                                    <Form.Control
                                                        style={{ maxWidth: '270px' }}
                                                        size='sm'
                                                        type="text"
                                                        placeholder="Search concepts"
                                                        onKeyDown={e => {
                                                            if (e.key === 'Enter') {
                                                                handleSearchConcept(e);
                                                            }
                                                        }}
                                                    />
                                                    
                                                    {!foundConcept && triggerAddConcept && (
                                                        <Button variant="outline-primary" size='sm' style={{ marginLeft: '10px' }} onClick={() => { handleModalAddConcept(inputConcept) }}>
                                                            Add
                                                        </Button>
                                                    )}
                                                </Form.Group>
                                                {(inputConcept !== '' && !foundConcept) && (
                                                    <div style={{ marginBottom: '10px' }}>
                                                        <Form.Text style={{ fontSize: '0.8em' }} className="text-danger">Concept not found. Add concept manually if needed.</Form.Text>
                                                    </div>
                                                )}
                                            </Form>
                                        )}
                                    </div>
                                </div>
                            </Row>
                            {/* Concepts */}
                            <Row className="sidebar" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                <h6 className="text-start">Hierarchy</h6>
                                {/* false is set for the isKnowledgeSite parameter as it is not needed here */}
                                {renderConceptBadges(false, sidebarItems, sidebarItems, setSidebarItems)}
                            </Row>
                            <hr />                            
                        </div>
                    </Col>
                    }
                    {/* Content: component areas */}
                    <Col style={{ textAlign: 'left' }} id="content"> {/* md={9}} */}
                        {/*{sidebarItems.length > 0 && (*/}
                            <>
                            {isFoldedView && (
                                <DroppableAreaFoldable 
                                    onDrop={handleDrop} 
                                    droppedItems={droppedItems} 
                                    handleAddElement={handleAddElement} 
                                    handleDeleteElement={handleDeleteElement}
                                    allExpanded={allExpanded}
                                    labels={compArchLabels}
                                />   
                            )}
                            {isDetailedView && (
                                <DroppableAreaDetailed 
                                    onDrop={handleDrop} 
                                    droppedItems={droppedItems} 
                                    handleAddElement={handleAddElement} 
                                    handleDeleteElement={handleDeleteElement} 
                                    labels={compArchLabels}
                                />
                            )}
                            </>
                        {/*})}*/}
                    </Col>
                </Row>
            </div>
            {/* show modal to add a concept (linked data) */}
            {showModal && (
                <ConceptModal
                    inputConcept={inputConcept}
                    onClose={handleCloseModal}
                    addNew={addNew}
                    newConcept={newConcept}
                />
            )}
        </DndProvider>
    );
};

export default Components;