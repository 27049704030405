

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebaseui/dist/firebaseui.css'; // Import Firebase UI CSS

// db
import { getFirestore, serverTimestamp } from 'firebase/firestore';

//import firebaseui from 'firebaseui'; 
import * as firebaseui from 'firebaseui';

// Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyDN-Oqo2wiEOVnvNfo2wyRxkKsgPXqL2jo",
  authDomain: "ucm-peccam.firebaseapp.com",
  projectId: "ucm-peccam",
  //storageBucket: "YOUR_STORAGE_BUCKET",
  messagingSenderId: "781721952378",
  appId: "781721952378"
};

// FirebaseUI configuration
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/start', // signedIn
  //  auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //requiredDisplayName: true,
      //signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
      //firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      //firebase.auth.GithubAuthProvider.PROVIDER_ID
    },
    /*
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    },*/
  ],
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true;
    },
    uiShown: function() {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById('loader').style.display = 'none';
    }
  },
  // Terms of service url.
  tosUrl: '<your-tos-url>',
  // Privacy policy url.
  privacyPolicyUrl: '<your-privacy-policy-url>'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

const ui = new firebaseui.auth.AuthUI(auth);

// Initialize Firestore database
const db = getFirestore(app);

export { app, ui, uiConfig, auth, db, serverTimestamp};