

import { useState } from 'react';
import { UserInformation, CurrentUser } from '../auth_mgmt/auth_handling';
//import { getCurrentDate } from '../utils/utils_date';

// Initial nodes for the actor graph
export const initialNodes = [    
    { id: 'Actor', label: 'Actors', group: 'Initial', font: { size: 20, bold: true, color: 'white' }, color: { background: 'black', border: 'black' }, widthConstraint: { minimum: 100 } },
    /*
    { id: 'Commerce', label: 'Commerce', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Governance', label: 'Governance', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Infrastructure', label: 'Infrastructure', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Public', label: 'Public', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },    
    */
    { id: 'Producer', label: 'Producer / Supplier', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Consumer', label: 'Consumer / Recipient', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Regulator', label: 'Regulator', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Technical', label: 'Technical Enabler', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Operator', label: 'Operator', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Platform', label: 'Platform Provider', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
];
  
// Initial edges for the actor graph
export const initialEdges = [
    /*
    { from: 'Actor', to: 'Commerce', dashes: true },
    { from: 'Actor', to: 'Governance', dashes: true },
    { from: 'Actor', to: 'Infrastructure', dashes: true },
    { from: 'Actor', to: 'Public', dashes: true },
    */
    { from: 'Actor', to: 'Producer', dashes: true },
    { from: 'Actor', to: 'Consumer', dashes: true },
    { from: 'Actor', to: 'Regulator', dashes: true },
    { from: 'Actor', to: 'Technical', dashes: true },
    { from: 'Actor', to: 'Operator', dashes: true },
    { from: 'Actor', to: 'Platform', dashes: true },    
];

/**
   * State to hold the use case data.
   * The data object will be updated as the user progresses through the form.
   * Data will be sent to the backend when the user completes the form.
   */
export const useCaseDataHook = () => {
    const [useCaseData, setUseCaseData] = useState({
    // step 1
    title: '',
    acronym: '',
    maturity: '',
    application: '',
    status: '',
    tags: [],
    city: [],
    country: [],
    country_code: [],
    country_flag: [],
    continents: [],
    description: '',
    created_by: UserInformation().displayName,
    created: '',
    updated: '',
    originally_created: '',
    ucid: '',
    originally_created_by: '',
    // step 2
    visibility: true, // visible in the repository
    adoption: true,
    share: false,
    semantic_model: true,
    domain_model: [''], // later removed, just needed in the alpha db as not initialized
    file: '',
    // step 3
    actions: [],
    conditions: [],
    // step 4
    actors: {
        list: {
          Producer: [],
          Consumer: [],
          Regulator: [],
          Technical: [],
          Operator: [],
          Platform: []
        },
        nodes: initialNodes,
        edges: initialEdges
      },   
    // step 5
    components: [],
    // user information
    user: UserInformation().displayName,
    user_first_name: CurrentUser(),
    user_id: UserInformation().uid,
});

return [useCaseData, setUseCaseData];
};

/**
 * Advanced state to hold the use case data.
 * Allows for more detailed tracking of the data source and modifications.
 * @returns {Array} useCaseData - the use case data object
 */
export const useCaseDataHookTrack = () => {
    const [useCaseData, setUseCaseData] = useState({
        title: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium',
        },
        acronym: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        maturity: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        application: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        status: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        tags: {
            value: [],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        city: {
            value: [],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        country: {
            value: [],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        country_code: {
            value: [],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        country_flag: {
            value: [],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        continents: {
            value: [],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        description: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        created_by: {
            value: UserInformation().displayName,
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        created: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        updated: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        originally_created: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        ucid: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        originally_created_by: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        visibility: {
            value: true,
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        adoption: {
            value: true,
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        share: {
            value: false,
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        semantic_model: {
            value: true,
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        domain_model: {
            value: [''],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        llm_model: {
            value: [],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: [],
            updated_by: [],
            uid: [],
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        file: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        actions: {
            value: [],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        conditions: {
            value: [],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        actors: {
            value: {
                list: {
                    Producer: {
                        value: [],
                        source: 'manual',
                        modified: false,
                        created_timestamp: '',
                        updated_timestamp: '',
                        updated_by: '',
                        uid: '',
                        version: 1,
                        priority: 'medium',
                        relevance: 'medium'
                    },
                    Consumer: {
                        value: [],
                        source: 'manual',
                        modified: false,
                        created_timestamp: '',
                        updated_timestamp: '',
                        updated_by: '',
                        uid: '',
                        version: 1,
                        priority: 'medium',
                        relevance: 'medium'
                    },
                    Regulator: {
                        value: [],
                        source: 'manual',
                        modified: false,
                        created_timestamp: '',
                        updated_timestamp: '',
                        updated_by: '',
                        uid: '',
                        version: 1,
                        priority: 'medium',
                        relevance: 'medium'
                    },
                    Technical: {
                        value: [],
                        source: 'manual',
                        modified: false,
                        created_timestamp: '',
                        updated_timestamp: '',
                        updated_by: '',
                        uid: '',
                        version: 1,
                        priority: 'medium',
                        relevance: 'medium'
                    },
                    Operator: {
                        value: [],
                        source: 'manual',
                        modified: false,
                        created_timestamp: '',
                        updated_timestamp: '',
                        updated_by: '',
                        uid: '',
                        version: 1,
                        priority: 'medium',
                        relevance: 'medium'
                    },
                    Platform: {
                        value: [],
                        source: 'manual',
                        modified: false,
                        created_timestamp: '',
                        updated_timestamp: '',
                        updated_by: '',
                        uid: '',
                        version: 1,
                        priority: 'medium',
                        relevance: 'medium'
                    }
                },
                nodes: {
                    value: initialNodes,
                    source: 'copilot',
                    modified: false,
                    created_timestamp: '',
                    updated_timestamp: '',
                    updated_by: '',
                    uid: '',
                    version: 1,
                    priority: 'medium',
                    relevance: 'medium'
                },
                edges: {
                    value: initialEdges,
                    source: 'copilot',
                    modified: false,
                    created_timestamp: '',
                    updated_timestamp: '',
                    updated_by: '',
                    uid: '',
                    version: 1,
                    priority: 'medium',
                    relevance: 'medium'
                }
            },
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        components: {
            value: [
                {
                    category: 'Operations',
                    components: [
                        { description: 'Operations: Systems, Services, and Tools', items: [] },
                        { description: 'Operations Management', items: [] },
                        { description: 'Operations Analytics', items: [] },
                        { description: 'Operations Platform', items: [] }
                    ]
                },
                {
                    category: 'Producer',
                    components: [
                        { description: 'Producer', items: [] },
                        { description: 'Producer Analytics', items: [] },
                        { description: 'Producer Management', items: [] },
                        { description: 'Producer Platform', items: [] }
                    ]
                },
                {
                    category: 'Consumer',
                    components: [
                        { description: 'Consumer', items: [] },
                        { description: 'Consumer Analytics', items: [] },
                        { description: 'Consumer Management', items: [] },
                        { description: 'Consumer Platform', items: [] }
                    ]                    
                },
                {
                    category: 'Regulator',
                    components: [
                        { description: 'Regulator', items: [] },
                        { description: 'Directives', items: [] },
                        { description: 'Reference Cases', items: [] },
                        { description: 'Standards', items: [] }
                    ]
                },
                {
                    category: 'Technical',
                    components: [
                        { description: 'Technical', items: [] },
                        { description: 'Application', items: [] },
                        { description: 'Platform', items: [] },
                        { description: 'Control', items: [] },
                        { description: 'Middleware', items: [] },
                        { description: 'Hardware', items: [] },
                    ]
                },            
            ],
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'medium'
        },
        user: {
            value: UserInformation().displayName,
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        user_first_name: {
            value: CurrentUser(),
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        user_id: {
            value: UserInformation().uid,
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        role: {
            value: 'user',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        department: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'medium',
            relevance: 'high'
        },
        benefits: {
            value: '',
            source: 'manual',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        history: {
            data: [],
            updated_by: [],
            uid: [],
        },
    });
    return [useCaseData, setUseCaseData];
};
