/**
 * Load bar component
 * 
 * Description: Used for instance when waiting for a response from the server
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-05-26
 * 
 */

import React, { useState, useRef, useEffect} from 'react';
import '../../loading_modal.css'; // Import your CSS file

const LoadingModal = ({ show, messages }) => {
    
    const [visible, setVisible] = useState(show);
    const modalRef = useRef(null);

    /*
    const asciiArt = `
      *    *   ****   *     * 
      *    *  *       **   ** 
      *    *  *       * * * * 
      *    *  *       *  *  * 
       ****    ****   *     * 
    `;
    */

    const consoleRef = useRef(null);
    const [consoleMessages, setConsoleMessages] = useState([
        'UCM use case generator',
        ' ',
        'Please wait while we prepare your use case',
        ' ',
        'Initialize...',
        ' ',
    ]);

    const phrases = [
        'initializing use case project',
        'this may take a minute',
        'setting up credentials',
        'loading the use case ontology',
        'fetching data to define your use case',
        'connecting to the domain model',   
        'building the use case model in  your domain field',        
        'processing dedicated requests',
        'identifying actors involved',
        'mapping dependencies of actors',
        'setting up the components',
        'configuring the use case model',
        'validating the elements',
        'compiling the use case',
        'almost there...',
        'today there is some delay, please wait a moment',
        'today there is some delay, please wait a moment',
        'today there is some delay, please wait a moment',
        'today there is some delay, please wait a moment',
        'today there is some delay, please wait a moment',
    ];

    useEffect(() => {
        if (messages) {
          setConsoleMessages(prevMessages => [...prevMessages, ...messages.split('\n')]);
        }
    }, [messages]);

    // Automatically scroll to the bottom of the console
    useEffect(() => {
        if (consoleRef.current) {
        consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
        }
    }, [consoleMessages]);

    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

    useEffect(() => {
        if (show) {
            setVisible(true);
            const interval = setInterval(() => {
                setCurrentPhraseIndex(prevIndex => (prevIndex + 1) % phrases.length);
            }, 4000); // Change phrase every 2 seconds

            return () => clearInterval(interval); // Cleanup interval on unmount
        } else if (modalRef.current) {
            modalRef.current.classList.add('fade-out');
            modalRef.current.addEventListener('animationend', () => {
                setVisible(false);
                modalRef.current.classList.remove('fade-out');
            }, { once: true });
        }
    }, [show, phrases.length]);

    // progress bar
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        if (!show) return;
        const interval = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress >= 100) {
              clearInterval(interval);
              return 100;
            }
            return prevProgress + 1;
          });
        }, 600); // 300 milliseconds interval for 30 seconds total
    
        return () => {
          clearInterval(interval);
        };
    }, [show]);

    return visible ? (
        <div className="loading-modal-overlay" style={ { zIndex:'3'} }>
            <div className="loading-modal" ref={modalRef}>
                <div className="loading-modal-content">
                    <div className="loading-animation">
                        <p>Getting you started</p>
                        <div className="loading-text">Creating your use case...</div>
                        <div className="loading-dots">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                        </div>
                        <div className="loading-phrases" style={{ color: 'green', fontSize: '1em' }}>
                            {phrases[currentPhraseIndex]}
                        </div>
                        <div className="progress" style={ { marginTop:'20px' } }>
                        <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                        </div>
                        {/* check if console messages are available */}
                        {consoleMessages.length > 0 &&
                        <div
                            ref={consoleRef}
                            style={{
                            marginTop: '20px',                           
                            backgroundColor: '#000',
                            //color: '#00f',
                            color: '#0f0',
                            padding: '10px',
                            height: '150px',
                            overflowY: 'scroll',
                            borderRadius: '5px',
                            fontFamily: 'monospace',
                            fontSize: '10px',
                            textAlign: 'left',
                            }}
                        >
                            {consoleMessages.map((msg, index) => (
                                <div key={index}>{msg}</div>
                            ))}
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default LoadingModal;