/**
 * Modal Add Edge
 *
 * Description: Modal to add an edge between two nodes
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-08-15
 * 
 */

import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddEdgeModal = ({ show, onHide, nodes, onAddEdge }) => {
    const [fromNodeId, setFromNodeId] = useState('');
    const [toNodeId, setToNodeId] = useState('');

    useEffect(() => {
        if (show) {
            const preSelectedNode = nodes.find(node => node.id === fromNodeId);
            setFromNodeId(preSelectedNode ? preSelectedNode.id : '');
        }
    }, [show, fromNodeId, nodes]);

    const handleAddEdge = () => {
        if (fromNodeId && toNodeId) {
            onAddEdge({ from: fromNodeId, to: toNodeId });
            setFromNodeId('');
            setToNodeId('');
            onHide();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            handleAddEdge();
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Edge</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formFromNode">
                        <Form.Label>Source Actor</Form.Label>
                        <Form.Control
                            as="select"
                            value={fromNodeId}
                            onChange={(e) => setFromNodeId(e.target.value)}
                            onKeyDown={handleKeyDown}
                        >
                            <option value="">Select node...</option>
                            {nodes.map((node) => (
                                <option key={node.id} value={node.id}>
                                    {node.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formToNode">
                        <Form.Label>Target Actor</Form.Label>
                        <Form.Control
                            as="select"
                            value={toNodeId}
                            onChange={(e) => setToNodeId(e.target.value)}
                            onKeyDown={handleKeyDown}
                        >
                            <option value="">Select node...</option>
                            {nodes.map((node) => (
                                <option key={node.id} value={node.id}>
                                    {node.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleAddEdge}>
                    Add edge
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddEdgeModal;