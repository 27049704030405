/**
 * Account
 *
 * Description: Contains the account page
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-24
 * 
 */

import React, { useState, useEffect } from 'react';
import { updateProfile, updatePassword } from 'firebase/auth';

import {SignOut, CurrentUser, UserInformation} from '../components/auth_mgmt/auth_handling'; // AuthStateListener

import { Container, Button, Row, Col, Form, Alert } from 'react-bootstrap';

export default function Account(props) {
  //const { user } = getAuth();

  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(UserInformation());
  }, []);

  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    try {
      if (user) {
        await updateProfile(user, { displayName });
        setMessage('Profile updated successfully.');
      }
    } catch (error) {
      setMessage(`Error updating profile: ${error.message}`);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      if (user && newPassword) {
        await updatePassword(user, newPassword);
        setMessage('Password updated successfully.');
      }
    } catch (error) {
      setMessage(`Error updating password: ${error.message}`);
    }
  };

  return (
    <Container fluid>
        {/* Intro */}
        <Row align='center'>      
            <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Account Management</b></h1>
            <p style={ { maxWidth: '500px', margin: '0 auto', marginBottom: '0px' } }>
            <i>Manage</i> your account. Update your profile and change your password.
            Contact us for any issues or questions.
            </p>
        </Row>
        {/* Profile Data */}
        <Row className="justify-content-center" style={{ marginTop: '30px' }}>
            <Col md={8} className="text-center">
            <h3><b>{CurrentUser()}, this is your profile data</b></h3>
            </Col>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: '30px' }}>
            <Col md={8} style={{ maxWidth: '500px', margin: '0 auto', marginBottom: '10px' }}>
            <Row className="d-flex align-items-center">
                <Col xs={6} className="text-right">
                <p><small>Your account is associated with the following email address:</small></p>
                </Col>
                <Col xs={6} className="text-left">
                <p><b>{user.email}</b></p>
                </Col>
            </Row>
            <Row className="d-flex align-items-center">
                <Col xs={6} className="text-right">
                <p><small>The display name used to identify you in the system is:</small></p>
                </Col>
                <Col xs={6} className="text-left">
                <p><b>{user.displayName}</b></p>
                </Col>
            </Row>
            <Row className="d-flex align-items-center">
                <Col xs={6} className="text-right">
                <p><small>Your user ID is:</small></p>
                </Col>
                <Col xs={6} className="text-left">
                <p><b>{user.uid}</b></p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                <p className="text-left">
                    <small>The user ID is unique and cannot be changed. It is assigned to your modeled use cases. The email address is used to send you notifications and updates.</small>
                </p>
                </Col>
            </Row>
            </Col>
        </Row>        
        {/* Account Management */}
        <Row align='center' style={ { maxWidth: '800px', margin: '0 auto'} }>
            <h3 style={ { marginTop: '30px', marginBottom: '30px' } }><b>Change Credentials</b></h3>
            {message && <Alert variant="info">{message}</Alert>}
            <Col>
              <Form onSubmit={handleProfileUpdate} className="mb-4">
                  <Form.Group controlId="formDisplayName" className="mb-3">
                  <Form.Label>Displayed User Name:</Form.Label>
                  <Form.Control
                      type="text"
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                  />
                  </Form.Group>
                  <Button variant="outline-primary" type="submit" disabled>
                    Update User Name 
                  </Button>
              </Form>
            </Col>
            <Col>
              <Form onSubmit={handleChangePassword}>
                  <Form.Group controlId="formNewPassword" className="mb-3">
                  <Form.Label>New Password:</Form.Label>
                  <Form.Control
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                  />
                  </Form.Group>
                  <Button variant="outline-primary" type="submit" disabled>
                  Change Password
                  </Button>
              </Form> 
            </Col>      
        </Row>
        {/* Reset password */}
        <Row align='center'>      
            <h3 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Password reset</b></h3>
            <p style={ { maxWidth: '500px', margin: '0 auto', marginBottom: '10px' } }>
                Forgot your password? No worries, we got you covered. Just click the button below to reset your password.                
            </p>
            <div>            
                <Button variant="outline-primary" style={{ marginRight: '10px' }} disabled>Reset Password</Button>
            </div>         
        </Row>  
        {/* Sign out */}
        <Row align='center'>      
            <h3 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Sign out</b></h3>
            <p style={ { maxWidth: '500px', margin: '0 auto', marginBottom: '10px' } }>
                You can sign out of your account at any time.
            </p>
            <div>            
                <Button variant="outline-primary" style={{ marginRight: '10px' }} onClick={SignOut}>Sign Out</Button>
            </div>
        </Row>
        {/* Delete account */}
        <Row align='center'>      
            <h3 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Delete account</b></h3>
            <p style={ { maxWidth: '500px', margin: '0 auto', marginBottom: '10px' } }>
                If you want to delete your account, click the button below. Your account will be permanently deleted.
                Attention: all public modeled use cases will remain in the repository. Privately modeled use cases will be deleted.
            </p>
            <div>            
                <Button variant="outline-danger" style={{ marginRight: '10px' }} disabled>Delete Account</Button>
            </div>
        </Row>
      </Container>
    );
};

export { default as Account } from './Account';
