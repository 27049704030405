/**
 * Ecosystem Analytics Charts
 * 
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-26 (last updated 30.06.2024)
 * 
 */

import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Col, Table, Modal } from 'react-bootstrap';
import { Bar, Pie, Scatter, Bubble, Line } from 'react-chartjs-2';

import { generateAnalyticsData, prepareChartData, aggregateData } from './eco_analytics_prepare_charts';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    ArcElement,
} from 'chart.js';
  
// Register the required components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  ArcElement
);

const StatisticsModeledUCs = ({ data, handleClear }) => {

  let analytics = generateAnalyticsData(data);

  const isAnalyticsReady = analytics && Object.keys(analytics).length > 0;

  const { actorCategories, totalActors, totalRelations, totalActions, componentCategories, totalComponents } = aggregateData(data);

  const [isBarChartAct, setIsBarChartAct] = useState(true);
  const [isBarChartComp, setIsBarChartComp] = useState(true);
  const actorBarData = prepareChartData(actorCategories, 'Actors per Category');
  const actorPieData = prepareChartData(actorCategories, 'Actors per Category');
  const componentBarData = prepareChartData(componentCategories, 'Components per Category');
  const componentPieData = prepareChartData(componentCategories, 'Components per Category');
  const [isKeyChart, setIsKeyChart] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  // Handle click on the generic statistics charts
  const handleGenericStatistics = (evt, elems, chartData, topic) => {

    if (elems.length > 0) {
      const clickedIndex = elems[0].index;
      const clickedCategory = chartData.labels[clickedIndex];

      // Filter use cases by the clicked category status
      let useCasesInCategory =  [];
      if (topic === 'Maturity Levels') {
        useCasesInCategory = data.filter((useCase) => useCase.maturity.value === clickedCategory);
      } else if (topic === 'Status') {
        useCasesInCategory = data.filter((useCase) => useCase.status.value === clickedCategory);
      } else if (topic === 'Application Areas') {
        useCasesInCategory = data.filter((useCase) => useCase.application.value === clickedCategory);
      }

      // Get the names of the filtered use cases
      const useCaseNames = useCasesInCategory.map((useCase) => useCase.title.value);

      const clickedData = {
        topic: topic,
        category: clickedCategory,
        res: useCaseNames,
      };

      setModalData(clickedData);
      setShowModal(true);
    }
  };

  // Handle click on the actors and components charts
  const handleChartClick = (evt, elems, chartData, topic) => {
    if (elems.length > 0) {
      const clickedIndex = elems[0].index;
      const clickedCategory = chartData.labels[clickedIndex];      
  
      let inCategory = [];
  
      if (topic === 'Actors per Category') {
        inCategory = data
          .flatMap((useCase) => useCase.actors.value.nodes.value)
          .filter((actor) => actor.group === clickedCategory);      
      } else if (topic === 'Components per Category') {
        inCategory = data
          .flatMap((useCase) => useCase.components.value)
          .filter((componentCategory) => componentCategory.category === clickedCategory)
          .flatMap((componentCategory) => componentCategory.components);
      }    

      const clickedData = {
        topic: topic,
        category: clickedCategory,
        res: inCategory,
      };
  
      setModalData(clickedData);
      setShowModal(true);
    }
  };

  const handleChartHover = (evt, elems) => {
    if (elems.length > 0) {
      evt.native.target.style.cursor = 'pointer';
    } else {
      evt.native.target.style.cursor = 'default';
    }
  };

  // Generate keyword frequency map
  const keywordFrequency = getKeywordFrequency(data);

  return (
    <Row>
      <Col md={12} style={{ textAlign: 'left' }} id="content">
        {/* Title */}
        <Row style={ { marginBottom:'20px', marginTop:'20px'} }>        
          <Col>
            <span>Summary of the modeled use cases</span>
          </Col>          
          <Col className="d-flex justify-content-end">                  
            <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} onClick={handleClear}>
              <i className="bi bi-arrow-left"></i> Go back
            </Button>
          </Col>   
        </Row>
                          
        {isAnalyticsReady && (
          <>
            {/* General */}
            <Row> 
              <Col md="6">
                <Row>
                  {/* Overall numbers */}
                  <Col md={6}>
                    <Card className="h-100">
                      <Card.Header>Available</Card.Header>
                      <Card.Body>
                        <Table borderless size="sm">
                          <tbody>
                            <tr>
                              <td><small>Total N° of use cases</small></td>
                              <td className="text-right"><strong>{analytics.totalUseCases}</strong></td>
                            </tr>
                            <tr>
                              <td><small>Total N° of actors</small></td>
                              <td className="text-right"><strong>{totalActors}</strong></td>
                            </tr>
                            <tr>
                              <td><small>Total N° of relations</small></td>
                              <td className="text-right"><strong>{totalRelations}</strong></td>
                            </tr>
                            <tr>
                              <td><small>Total N° of actions</small></td>
                              <td className="text-right"><strong>{totalActions}</strong></td>
                            </tr>
                            <tr>
                              <td><small>Total N° of conditions</small></td>
                              <td className="text-right"><strong>{analytics.totalConditions}</strong></td>
                            </tr>
                            <tr>
                              <td><small>Total N° of components</small></td>
                              <td className="text-right"><strong>{totalComponents}</strong></td>
                            </tr>                            
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* Application area */}
                  <Col md={6}>
                    <Card className="h-100">
                      <Card.Header>Application Areas</Card.Header>
                      <Card.Body>
                        <Pie data={
                          prepareChartData(analytics.application, 'Application Areas')} 
                          options={{ 
                            responsive: true, 
                            maintainAspectRatio: false,
                            onClick: (evt, elems) => handleGenericStatistics(evt, elems, prepareChartData(analytics.application, 'Application'), 'Application Areas'),
                            onHover: (evt, elems) => handleChartHover(evt, elems),
                          }} 
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ marginTop:'15px'}}>
                  {/* Maturity levels */}
                  <Col md={6}>
                      <Card className="h-100">
                        <Card.Header>Maturity Levels</Card.Header>
                        <Card.Body>
                          <Bar data={
                            prepareChartData(analytics.maturityLevels, 'Maturity Levels')} 
                            options={{ 
                              responsive: true, 
                              maintainAspectRatio: false,
                              onClick: (evt, elems) => handleGenericStatistics(evt, elems, prepareChartData(analytics.maturityLevels, 'Maturity'), 'Maturity Levels'),
                              onHover: (evt, elems) => handleChartHover(evt, elems),
                            }} 
                          />
                        </Card.Body>
                      </Card>
                  </Col>
                  {/* status */}
                  <Col md={6}>
                    <Card className="h-100">
                      <Card.Header>Status</Card.Header>
                      <Card.Body>
                        <Pie 
                          data={prepareChartData(analytics.status, 'status')} 
                          options={{ 
                            responsive: true, 
                            maintainAspectRatio: false,
                            onClick: (evt, elems) => handleGenericStatistics(evt, elems, prepareChartData(analytics.status, 'status'), 'Status'),
                            onHover: (evt, elems) => handleChartHover(evt, elems),
                            }} 
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>                          
              </Col>
              <Col md="6">
                {/* Compare key terms and attributes against the use cases */}
                <Card className="h-100">
                  <Card.Header>Use Case Distribution at the Intersection of Application and Topic Areas</Card.Header>
                  <Card.Body>
                    <CompareKeyTerms data={data} /> 
                  </Card.Body>
              </Card>
              </Col>
            </Row>  
            {/* Actors and components per category */}
            <Row style={{ marginTop: '20px' }}>
              {/* Actor categories */}
              <Col md={6}>
                <Card className="h-100">
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <span>Actors per Category</span>
                    <Button variant="outline-primary" className="btn-sm" onClick={() => setIsBarChartAct(!isBarChartAct)}>
                      {isBarChartAct ? (
                        <i className="bi bi-pie-chart"></i>
                      ) : (
                        <i className="bi bi-bar-chart"></i>
                      )}
                    </Button>
                  </Card.Header>
                  <Card.Body>
                    {isBarChartAct && (
                      <Bar 
                        data={actorBarData} 
                        options={{ 
                          responsive: true, 
                          maintainAspectRatio: false, 
                          onClick: (evt, elems) => handleChartClick(evt, elems, actorBarData, 'Actors per Category'),
                          onHover: (evt, elems) => handleChartHover(evt, elems),
                        }} 
                      />
                    )}
                    {!isBarChartAct && (
                      <Pie 
                        data={actorPieData} 
                        options={{ 
                          responsive: true, 
                          maintainAspectRatio: false, 
                          onClick: (evt, elems) => handleChartClick(evt, elems, actorPieData, 'Actors per Category'),
                          onHover: (evt, elems) => handleChartHover(evt, elems)
                        }} 
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
              {/* Component categories */}
              <Col md={6}>
                <Card className="h-100">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <span>Components per Category</span>
                    <Button variant="outline-primary" className="btn-sm" onClick={() => setIsBarChartComp(!isBarChartComp)}>
                      {isBarChartComp ? (
                        <i className="bi bi-pie-chart"></i>
                      ) : (
                        <i className="bi bi-bar-chart"></i>
                      )}
                    </Button>
                  </Card.Header>
                  <Card.Body>
                    {isBarChartComp && (
                      <Bar 
                        data={componentBarData} 
                        options={{ 
                          responsive: true, 
                          maintainAspectRatio: false,
                          onClick: (evt, elems) => handleChartClick(evt, elems, componentBarData, 'Components per Category'),
                          onHover: (evt, elems) => handleChartHover(evt, elems)
                        }} 
                      />
                    )}
                    {!isBarChartComp && (
                      <Pie 
                        data={componentPieData} 
                        options={{ 
                          responsive: true, 
                          maintainAspectRatio: false,
                          onClick: (evt, elems) => handleChartClick(evt, elems, componentPieData, 'Components per Category'),
                          onHover: (evt, elems) => handleChartHover(evt, elems),
                        }} 
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row> 
            {/* Most common keywords */}
            <Row style={{ marginTop: '20px' }}>
              <Col md={6}>
                <Card className="h-100">
                  <Card.Header>Most Common Keywords</Card.Header>
                  <Card.Body>
                    <KeywordFrequencyChart keywordFrequency={keywordFrequency} />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="h-100">
                  <Card.Header>Keywords per Use Case</Card.Header>
                  <Card.Body>
                    <MostCommonKeywordsChart data={data} topN={5} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>    
            <Row style={{ marginTop: '20px' }}>
              <Col md={12}>
                <TagWordCloud tagFrequency={analytics.tagFrequency} />
              </Col>
            </Row>       
            {/* Keywords statistics */}
            <Row style={{ marginTop: '20px' }}>
              <Col md={12}>
                <Card className="h-100">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                      <span>Actors per Category</span>
                      <Button variant="outline-primary" className="btn-sm" onClick={() => setIsKeyChart(!isKeyChart)}>
                        {isKeyChart ? (
                          <i className="bi bi-table"></i>
                        ) : (
                          <i className="bi bi-bar-chart"></i>
                        )}
                      </Button>
                    </Card.Header>
                    <Card.Body>
                      {isKeyChart && (
                        <BubbleChart data={data} />
                      )}
                      {!isKeyChart && (
                        <KeywordsPerUseCaseTable data={data} />
                      )}
                    </Card.Body>
                </Card>
              </Col>
            </Row>           
            {/* Component usage over time */}
            <Row style={{ marginTop: '20px' }}>
              <Col md={12}>
                <ComponentUsageOverTimeChart components={data.components} timeSeries={analytics.timeSeries} />
              </Col>
            </Row>

            {modalData && <DetailModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} />}
          </>
        )}
      </Col>
    </Row>  
  );
};

const groupItemsByDescription = (data) => {
  return data.reduce((acc, component) => {
    const description = component.description || 'Unknown';
    if (!acc[description]) {
      acc[description] = [];
    }
    acc[description].push(...component.items);
    return acc;
  }, {});
};

const DetailModal = ({ show, handleClose, data }) => (

  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{data.topic}: {data.category}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <span>List of use cases matching: <b>{data.category}</b> (Total: <b>{data.res.length}</b>)</span>
      {data.topic === 'Actors per Category' && (
        <ul>
          {data.res.map((actor, index) => (
            <li key={index}>{actor.label}</li>
          ))}
        </ul>
      )}
      {data.topic === 'Components per Category' && (
        <ul>
        {Object.entries(groupItemsByDescription(data.res)).map(([description, items], index) => (
          <li key={index}>
            <strong>Description:</strong> {description}<br />
            <strong>Items:</strong><br />
            {items.map((item, idx) => (
              <span key={idx} className="badge bg-light text-dark" style={{ marginLeft: '5px' }}>
                  {item}
              </span>

            ))}
            <br />
          </li>
        ))}
        </ul>
      )}
      {(data.topic === 'Status' || data.topic === 'Maturity Levels' || data.topic === 'Application Areas') && (
        <ul>
          {data.res.map((res, index) => (
            <li key={index}>{res}</li>
          ))}
        </ul>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

// Line chart for component usage over time
const ComponentUsageOverTimeChart = ({ components, timeSeries }) => {
  // Your chart implementation here
  // ...
  return (
    <div>
      <h5>Component Usage Over Time</h5>
      <Line
        data={{
          labels: timeSeries.map(entry => entry.date),
          datasets: [
            {
              label: 'Component Usage Over Time',
              data: timeSeries.map(entry => entry.componentUsage),
              borderColor: 'rgba(75,192,192,1)',
              backgroundColor: 'rgba(75,192,192,0.2)',
            },
          ],
        }}
      />
    </div>
  );
};

const TagWordCloud = ({ tagFrequency }) => {
  // Calculate the maximum and minimum values to scale the font sizes
  const maxFontSize = 50;
  const minFontSize = 15;
  const maxValue = Math.max(...tagFrequency.map(tag => tag.value));
  const minValue = Math.min(...tagFrequency.map(tag => tag.value));

  // Function to scale the font size based on the value
  const getFontSize = (value) => {
    return ((value - minValue) / (maxValue - minValue)) * (maxFontSize - minFontSize) + minFontSize;
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
      {tagFrequency.map((tag, index) => (
        <span
          key={index}
          style={{
            margin: '5px',
            fontSize: `${getFontSize(tag.value)}px`,
            color: '#61dafb'
          }}
        >
          {tag.text}
        </span>
      ))}
    </div>
  );
};

// Compare key terms with application areas etc for use cases
const prepareScatterPlotData = (data) => {
  const applicationAreas = Array.from(new Set(data.map(useCase => useCase.application.value)));
  const keywords = Array.from(new Set(data.flatMap(useCase => useCase.tags.value)));

  const dataset = data.map(useCase => ({
    x: keywords.indexOf(useCase.tags.value[0]),  // Assuming the first tag represents the use case
    y: applicationAreas.indexOf(useCase.application.value),
    label: useCase.title
  }));

  return {
    applicationAreas,
    keywords,
    dataset
  };
};

const CompareKeyTerms = ({ data }) => {
  const [scatterData, setScatterData] = useState(null);

  useEffect(() => {
    if (data) {
      const preparedData = prepareScatterPlotData(data);
      setScatterData(preparedData);
    }
  }, [data]);

  if (!scatterData) {
    return null;
  }

  const chartData = {
    datasets: [{
      label: 'Use Cases',
      data: scatterData.dataset,
      backgroundColor: 'rgba(75,192,192,1)',
      pointRadius: 5,
      pointHoverRadius: 7,
    }]
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Keywords'
        },
        ticks: {
          callback: function(value, index, values) {
            return scatterData.keywords[value];
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Application Area'
        },
        ticks: {
          callback: function(value, index, values) {
            return scatterData.applicationAreas[value];
          }
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.raw.label}: ${context.raw.label}`;
          }
        }
      }
    }
  };

  return <Scatter data={chartData} options={options} />;
};

// Bar chart for most common keywords
const MostCommonKeywordsChart = ({ data, topN }) => {
  const mostCommonKeywords = getMostCommonKeywords(data, topN);

  const chartData = {
    labels: mostCommonKeywords.map(item => item.keyword),
    datasets: [
      {
        label: 'Frequency',
        data: mostCommonKeywords.map(item => item.frequency),
        backgroundColor: [
          'rgba(75,192,192,0.4)', 
          'rgba(153,102,255,0.4)', 
          'rgba(255,159,64,0.4)',
          'rgba(75,192,192,0.6)',
          'rgba(153,102,255,0.6)',
          'rgba(255,159,64,0.6)',
          'rgba(75,192,192,0.8)',
          'rgba(153,102,255,0.8)',
          'rgba(255,159,64,0.8)',
          'rgba(75,192,192,1)',
        ],
        borderColor: [
          'rgba(75,192,192,1)',
          'rgba(153,102,255,1)',
          'rgba(255,159,64,1)',
          'rgba(75,192,192,1)',
          'rgba(153,102,255,1)',
          'rgba(255,159,64,1)',
          'rgba(75,192,192,1)',
          'rgba(153,102,255,1)',
          'rgba(255,159,64,1)',
          'rgba(75,192,192,1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Keywords',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Frequency',
        },
        min: 0,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

// Use cases per keyword chart

// Function to get the bubble chart data
const prepareBubbleChartData = (data) => {
  const useCasesPerKeyword = getUseCasesPerKeyword(data);
  const keywordFrequency = getKeywordFrequency(data);

  const chartData = {
    datasets: Object.keys(useCasesPerKeyword).map((keyword, index) => ({
      label: keyword,
      data: [{
        x: Math.random() * 100,  // Random x position
        y: Math.random() * 100,  // Random y position
        r: keywordFrequency[keyword] * 5  // Bubble radius based on frequency
      }],
      backgroundColor: `rgba(${index * 30 % 255}, ${index * 60 % 255}, ${index * 90 % 255}, 0.6)`,
      borderColor: `rgba(${index * 30 % 255}, ${index * 60 % 255}, ${index * 90 % 255}, 1)`,
      borderWidth: 1
    }))
  };

  return chartData;
};

const BubbleChart = ({ data }) => {
  const chartData = prepareBubbleChartData(data);

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'X Axis'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Y Axis'
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.dataset.label || '';
            const value = context.raw.r / 5;
            return `${label}: ${value} use cases`;
          }
        }
      }
    }
  };

  return (
    <div style={{ height: '600px', width: '100%' }}>
      <Bubble data={chartData} options={options} />
    </div>
  );
};

// Function to list keywords per use case
const KeywordsPerUseCaseTable = ({ data }) => {
  const keywordsPerUseCase = getKeywordsPerUseCase(data);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Use Case Title</th>
          <th>Keywords</th>
        </tr>
      </thead>
      <tbody>
        {keywordsPerUseCase.map((useCase, index) => (
          <tr key={index}>
            <td>{useCase.useCaseTitle}</td>
            <td>{useCase.keywords.join(', ')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

// keywords insights for use cases
const KeywordFrequencyChart = ({ keywordFrequency }) => {
  const data = {
      labels: Object.keys(keywordFrequency),
      datasets: [
          {
              label: 'Keyword Frequency',
              data: Object.values(keywordFrequency),
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              borderWidth: 1,
          },
      ],
  };

  const options = {
      scales: {
          x: {
              title: {
                  display: true,
                  text: 'Keywords',
              },
          },
          y: {
              title: {
                  display: true,
                  text: 'Frequency',
              },
              min: 0,
              ticks: {
                  stepSize: 1,
              },
          },
      },
  };

  return <Bar data={data} options={options} />;
};

// Function to generate keyword frequency map
const getKeywordFrequency = (data) => {
  const frequencyMap = {};

  data.forEach(useCase => {
      useCase.tags.value.forEach(tag => {
          const keyword = tag.trim().toLowerCase();
          frequencyMap[keyword] = (frequencyMap[keyword] || 0) + 1;
      });
  });

  return frequencyMap;
};

// Function to list keywords per use case
const getKeywordsPerUseCase = (data) => {
  return data.map(useCase => ({
      useCaseTitle: useCase.title,
      keywords: useCase.tags.value.map(tag => tag.trim().toLowerCase())
  }));
};

// Function to get use cases per keyword
const getUseCasesPerKeyword = (data) => {
  const useCasesMap = {};

  data.forEach(useCase => {
      useCase.tags.value.forEach(tag => {
          const keyword = tag.trim().toLowerCase();
          if (!useCasesMap[keyword]) {
              useCasesMap[keyword] = [];
          }
          useCasesMap[keyword].push(useCase.title);
      });
  });

  return useCasesMap;
};

// Function to get most common keywords
const getMostCommonKeywords = (data, topN = 10) => {
  const frequencyMap = getKeywordFrequency(data);
  const sortedKeywords = Object.keys(frequencyMap).sort((a, b) => frequencyMap[b] - frequencyMap[a]);
  
  return sortedKeywords.slice(0, topN).map(keyword => ({
      keyword,
      frequency: frequencyMap[keyword]
  }));
};


export { StatisticsModeledUCs };