/**
 * Describes the procedure for the user study
 *
 * Description: Provides the elements
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-15
 * Notes: /
 */

import React from 'react';

import { Button, Container, Row, Nav, Tab } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

/**
 * Provides the manual for the study
 * @returns 
 */
const Manual = () => {
    return (
        <Tab.Container id="v-pills-tab" defaultActiveKey="objective">
      <div className="d-flex align-items-start">
        <Nav className="flex-column me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <Nav.Item>
            <Nav.Link eventKey="objective">Objective</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="task">Your task</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="outcome">Outcome</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="usage">Usage</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="objective">
            <p>
                The objective of the study is to create a use case based on the GUCO and PEAMONTO ontologies.
                The use case should be related to Autonomous Mobility and Transportation. You are required to provide 
                the necessary information to create the use case.                                
            </p>
          </Tab.Pane>
          <Tab.Pane eventKey="task">
            <p>
                Your task is to follow the steps provided in the manual and provide the necessary information to create the use case.
                The information includes the title, acronym, description, actors, actions, preconditions, postconditions, constraints, and assumptions.
            </p>
          </Tab.Pane>
          <Tab.Pane eventKey="outcome">
            <p>
                The outcome of the study is a use case based on the GUCO and PEAMONTO ontologies. The use case should be related to Autonomous Mobility and Transportation.
                The use case will be integrated into the research study and used for further analysis.
            </p>
          </Tab.Pane>
          <Tab.Pane eventKey="usage">
            <p>
                The study is designed to support research in the field of Ontology-Driven Use Case Mapping and with emphasis on Autonomous Mobility in the Platform Economy.
                The use case will be used to create a repository of use cases that can be used for further research and analysis.
            </p>
          </Tab.Pane>
        </Tab.Content>
      </div>
    </Tab.Container>
      
    );
  }
  
export default function Instructions(props) {

    const navigate = useNavigate();
    // creator, cockpit
    const navigateCockpit = () => {
        navigate('/Create');
    };

    return (
        <Container fluid>
            {/* Instructions */}
            <Row align='center'>      
                <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Instructions</b></h1>
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                    These <i>instructions</i> serve as a manual to guide you through the respective steps of the study.
                    Please follow the steps and provide the necessary information to create your envisioned use case.
                    You can always refer to the <i>UCM Association Guide</i> for further assistance.
                </p>
            </Row>
            {/* Manual and Scope */}       
            <Row align='center'>
                <h3 style={{marginTop: '30px', marginBottom: '30px'}}>Manual and Scope of the Study</h3>
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                    Please refer to the manual below to understand the objective, your task, the outcome, and the usage of the study.
                </p>                        
            </Row>
            {/* Call Manual */}
            <Row>
                <div style={ { maxWidth: '700px', margin: '0 auto', marginTop: '30px', marginBottom: '10px' } }>
                    <Manual />                                   
                </div>  
            </Row>
            {/* Navigate */}
            <Row align='center'>
                <div>
                    <Button variant="primary" style={{ marginRight: '10px' }} onClick={navigateCockpit}>Start modeling</Button>
                </div>                
            </Row>
        </Container>
    );
};

export { default as Instructions } from './Instructions';