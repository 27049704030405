/**
 * Model Actor View
 * 
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-17
 * 
 */

import { Accordion, Badge, ListGroup } from 'react-bootstrap';

export const ActorView = ({ nodes, expandedCategory, onToggle }) => {
    // Group nodes by their categories
    const categoryNodes = nodes.value.reduce((acc, node) => {
      if (!acc[node.group]) {
        acc[node.group] = [];
      }
      acc[node.group].push(node);
      return acc;
    }, {});
  
    return (
      <Accordion activeKey={expandedCategory}>
        {Object.keys(categoryNodes).map((category, index) => (
          <Accordion.Item eventKey={category} key={category}>
            <Accordion.Header onClick={() => onToggle(category)}>
              <div className="d-flex justify-content-between align-items-center" style={{ width: '100%', marginRight:'20px' }}>
                <strong>{category}</strong>
                <Badge pill bg="primary">
                  {categoryNodes[category].length}
                </Badge>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup>
                {categoryNodes[category].map((node) => (
                  <ListGroup.Item key={node.id}>
                    <Badge bg="light" text="dark" style={{ margin: '5px' }}>
                      {node.label}
                    </Badge>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    );
};

export const ComponentView = ({ components, expandedCategory, onToggle }) => {
  return (
    <Accordion activeKey={expandedCategory}>
      {components.map((category, catIndex) => (
        <Accordion.Item eventKey={category.category} key={catIndex}>
          <Accordion.Header onClick={() => onToggle(category.category)}>{category.category}</Accordion.Header>
          <Accordion.Body>
            <ListGroup>
              {category.components.map((component, compIndex) => (
                <ListGroup.Item key={compIndex}>
                  <strong>{component.description}</strong>
                  <div>
                    <p>Type: {component.type}</p>
                    <p>Status: {component.status}</p>
                    <div>
                      {component.items.map((item, itemIndex) => (
                        <Badge key={itemIndex} bg="light" text="dark" style={{ margin: '5px' }}>
                          {item}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export const ActorComponentView = ({ data, expandedCategory, onToggle }) => {
  return (
    <Accordion activeKey={expandedCategory}>
      <Accordion.Item eventKey="actors">
        <Accordion.Header onClick={() => onToggle('actors')}>
          <div className="d-flex justify-content-between align-items-center" style={{ width: '100%', marginRight:'20px' }}>
            <strong>Actors</strong>
            <Badge pill bg="primary">
              {data.actors.nodes.length}
            </Badge>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <ActorView nodes={data.actors.nodes} expandedCategory={expandedCategory} onToggle={onToggle} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="components">
        <Accordion.Header onClick={() => onToggle('components')}>
          <div className="d-flex justify-content-between align-items-center" style={{ width: '100%', marginRight:'20px' }}>
            <strong>Components</strong>
            <Badge pill bg="primary">
              {data.components.reduce((total, category) => total + category.components.length, 0)}
            </Badge>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <ComponentView components={data.components} expandedCategory={expandedCategory} onToggle={onToggle} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};