/**
 * Chart elements for the analytics page
 *
 * Description: Provides the necessary chart elements for the analytics page.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-14
 * 
 */

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { BarChart, PieChart } from '../analytics_handling/analytics_charts';

import { categorizeUseCases, calculateMatchingScore } from './analytics_prepare_data';
import { MatchingScoreModal } from './analytics_modal_score';

import { ActorListTable, ActorBarChart, ComponentListTable, ComponentBarChart, ComponentBarChartNested, NodeCentrality, TopConnectedNodes } from './analytics_charts';
import { ActorView, ComponentView } from './analytics_uc_actor_components';

/**
 * Cards to display the statistics of the analysis
 * @param {*} param0 
 * @returns 
 */
const StatisticsCard = ({ col, number, caption }) => {

    return (
        <Col md={col}>
        <Card className="text-center h-100" style={{ padding: '10px' }}>
            <Card.Body>
            <Card.Title style={{ fontSize: '45px', fontWeight: 'bold' }}>
                {number}
            </Card.Title>
            <Card.Text style={{ fontSize: '14px' }}>
                {caption}
            </Card.Text>
            </Card.Body>
        </Card>
        </Col>
  );
};

export const UCInsight = ({ data, selectedUseCase, comparisonData, setModalData, setShowModal }) => {

    // State variables for statistics: similar, distinct, overlapping
    const [stats, setStats] = useState({ similar: 0, distinct: 0, overlapping: 0 });
    const [matchingScore, setMatchingScore] = useState(0);
    const [showModalScore, setShowModalScore] = useState(false);    

    // State variables for statistics: application, maturity, status
    const [isCompApplicationBar, setIsCompApplicationBar] = useState(true);
    const [isCompMaturityBar, setIsCompMaturityBar] = useState(true);
    const [isCompStatusBar, setIsCompStatusBar] = useState(true);

    // State variables for chart data COMPONENTS: table, bar, nested bar
    const [isComponentsBar, setIsComponentsBar] = useState(true);
    const [isComponentsBarNested, setIsComponentsBarNested] = useState(false);
    const [isComponentsTable, setIsComponentsTable] = useState(false);

    // State variables for chart data ACTOR: table, bar, nested bar
    const [isActorBar, setIsActorBar] = useState(true);
    const [isActorTable, setIsActorTable] = useState(false);

    // State variables for chart data ACTOR: node centrality, top connected nodes
    const [isActorNodeCentral, setIsActorNodeCentral] = useState(true);
    const [isActorTopConnected, setIsActorTopConnected] = useState(false);

    // Handling for statistics: score, similar, distinct, overlapping
    useEffect(() => {
        if (selectedUseCase && data.length > 0) {
            const categorizedStats = categorizeUseCases(selectedUseCase, data);
            setStats(categorizedStats);

            const score = calculateMatchingScore(selectedUseCase, data);
            setMatchingScore(score);
        }
        
    }, [selectedUseCase, data]);    
  
    const handleCompApplication = () => {
      setIsCompApplicationBar(!isCompApplicationBar);
    };
  
    const handleComMaturity = () => {
      setIsCompMaturityBar(!isCompMaturityBar);
    };
    
    const handleCompStatus = () => {
      setIsCompStatusBar(!isCompStatusBar);
    };
  
    const handleChartClick = (evt, elems, chartData, attribute) => {
  
        if (elems.length > 0) {
          const clickedIndex = elems[0].index;
          const clickedCategory = chartData.labels[clickedIndex];
        
          // Filter use cases by the clicked category based on the attribute
          let useCasesInCategory = [];
          
          if (attribute === 'maturity') {
            useCasesInCategory = data.filter((useCase) => useCase.maturity.value === clickedCategory);
          } else if (attribute === 'status') {
            useCasesInCategory = data.filter((useCase) => useCase.status.value === clickedCategory);
          } else if (attribute === 'application') {
            useCasesInCategory = data.filter((useCase) => useCase.application.value === clickedCategory);
          }
        
          // Get the names of the filtered use cases
          const useCaseNames = useCasesInCategory.map((useCase) => useCase.title.value);
        
          const clickedData = {
            topic: attribute,
            category: clickedCategory,
            res: useCaseNames,
          };
        
          setModalData(clickedData);
          setShowModal(true);
        }
    };

    const handleChartClickAC = (evt, elems, chartData, topic) => {
        if (elems.length > 0) {
            const clickedIndex = elems[0].index;
            const clickedCategory = chartData.labels[clickedIndex];
    
            let inCategory = [];
    
            if (topic === 'Actors per Category') {
                // Assuming actors are now stored in selectedUseCase.actors.value.nodes.value
                inCategory = selectedUseCase.actors.value.nodes.value.filter(
                    (actor) => actor.group === clickedCategory
                );
            } else if (topic === 'Components per Category') {
                if (isComponentsBarNested) {
                    const [mainCategory, subCategory] = clickedCategory.split(' - ');
                    const category = selectedUseCase.components.value.find(
                        (componentCategory) => componentCategory.category === mainCategory
                    );
                    if (category) {
                        const subCat = category.components.find(
                            (comp) => comp.description === subCategory
                        );
                        inCategory = subCat ? subCat.items : [];
                    }
                } else {
                    const category = selectedUseCase.components.value.find(
                        (componentCategory) => componentCategory.category === clickedCategory
                    );
                    inCategory = category ? category.components.flatMap((comp) => comp.items) : [];
                }
            }
    
            const clickedData = {
                topic: topic,
                category: clickedCategory,
                res: inCategory,
            };
    
            setModalData(clickedData);
            setShowModal(true);
        }
    };
    
    const handleChartHover = (evt, elems) => {
      if (elems.length > 0) {
        evt.native.target.style.cursor = 'pointer';
      } else {
        evt.native.target.style.cursor = 'default';
      }
    };

    const chartContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '300px'
    };

    // Handling for components: table, bar, nested bar
    const handleComponentBar = () => {
        setIsComponentsBar(!isComponentsBar);
        setIsComponentsBarNested(false);
        setIsComponentsTable(false);
    };

    const handleComponentBarNested = () => {
        setIsComponentsBarNested(!isComponentsBarNested);
        setIsComponentsBar(false);
        setIsComponentsTable(false);
    }

    const handleComponentTable = () => {
        setIsComponentsTable(!isComponentsTable);
        setIsComponentsBar(false);
        setIsComponentsBarNested(false);
    }

    // Handling for actors: table, bar, nested bar
    const handleActorBar = () => {
        setIsActorBar(!isActorBar);
        setIsActorTable(false);
    };

    const handleActorTable = () => {
        setIsActorTable(!isActorTable);
        setIsActorBar(false);
    }

    // Handling for actors: node centrality, top connected nodes

    const handleActorNodeCentral = () => {
        setIsActorNodeCentral(!isActorNodeCentral);
        setIsActorTopConnected(false);
    }

    const handleActorTopConnected = () => {
        setIsActorTopConnected(!isActorTopConnected);
        setIsActorNodeCentral(false);
    }

    // Handle the expansion of categories of actors and components
    const [expandedCategory, setExpandedCategory] = useState(null);
    const handleToggle = (category) => {
        setExpandedCategory(category === expandedCategory ? null : category);
    }; 

    return (
        <Row style={ { marginTop:'20px'} }>
            {/* Use Case Insights - General Statistics */}
            <Col md={12}>
                <Row style={ { marginBottom:'20px'} }>
                    Use Case Insights
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} disabled>
                            <i className="bi bi-info-circle"></i>
                        </Button>
                    </Col>                
                </Row>    

                <Row style={ { marginBottom:'20px'} }>
                    {/* Matching score to existing modeled components*/}
                    <Col md={5}>        
                        <Row style={{ height: '100%' }}>
                            <StatisticsCard col="7" number={matchingScore} caption="Matching Score" />          
                            <Col md={5}>
                                <span>
                                    <small>
                                        <i className="bi bi-info-circle"></i> Matching score indicator {/*indicates how well the selected use case maps in the ecosystem of modeled use cases.*/}                                                                                            
                                    </small>
                                </span>
                                <Button 
                                    variant="outline-primary" 
                                    className="btn-sm" 
                                    style={{ marginLeft: '10px', marginTop:'15px' }}
                                    onClick={() => setShowModalScore(true)}
                                >
                                    <i className="bi bi-calculator"></i>
                                </Button>
                            </Col>
                        </Row>                                                    
                    </Col>      
                    <Col md={7}>
                        {/* Findings at a glance */}
                        <Row style={{ height: '100%' }}>
                            <StatisticsCard col="4" number={stats.similar} caption="Similar Use Cases" />
                            <StatisticsCard col="4" number={stats.overlapping} caption="Overlapping Use Cases" />   
                            <StatisticsCard col="4"number={stats.distinct} caption="Distinct Use Cases" />                                       
                        </Row>       
                    </Col>          
                </Row>    
            </Col>
            {/* Use Case Insights - Comparison Charts */}
            <Col md={12}>                     
                <Row style={ { marginTop:'20px' } }>
                    {/* Application area */}
                    <Col md={4}>
                        <div className="d-flex justify-content-between align-items-center">
                                <h5>Application area</h5>
                                <Button variant="outline-primary" className="btn-sm" onClick={handleCompApplication}>
                                    {isCompApplicationBar ? (
                                    <i className="bi bi-pie-chart"></i>
                                    ) : (
                                    <i className="bi bi-bar-chart"></i>
                                    )}
                                </Button>
                            </div>
                            <div style={chartContainerStyle}>
                            {isCompApplicationBar ? (
                                <BarChart 
                                    comparisonData={comparisonData} 
                                    attribute={'application'} 
                                    selectedUseCase={selectedUseCase} 
                                    handleChartClick={handleChartClick}
                                    handleChartHover={handleChartHover}
                                />                                                
                                ) : (                      
                                <PieChart comparisonData={comparisonData} attribute={'application'} />                                                  
                            )}
                        </div>
                    </Col>
                    {/* Maturity */}
                    <Col md={4}>                      
                        <div className="d-flex justify-content-between align-items-center">
                                <h5>Maturity</h5>
                                <Button variant="outline-primary" className="btn-sm" onClick={handleComMaturity}>
                                    {isCompMaturityBar ? (
                                    <i className="bi bi-pie-chart"></i>
                                    ) : (
                                    <i className="bi bi-bar-chart"></i>
                                    )}
                                </Button>
                            </div>
                            <div style={chartContainerStyle}>
                            {isCompMaturityBar ? (
                                <BarChart 
                                    comparisonData={comparisonData} 
                                    attribute={'maturity'} 
                                    selectedUseCase={selectedUseCase} 
                                    handleChartClick={handleChartClick}
                                    handleChartHover={handleChartHover}
                                />
                                ) : (
                                <PieChart comparisonData={comparisonData} attribute={'maturity'} />
                            )}
                        </div>
                    </Col>
                    {/* Status */}
                    <Col md={4}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Status</h5>
                            <Button variant="outline-primary" className="btn-sm" onClick={handleCompStatus}>
                                {isCompStatusBar ? (
                                <i className="bi bi-pie-chart"></i>
                                ) : (
                                <i className="bi bi-bar-chart"></i>
                                )}
                            </Button>
                        </div>      
                        <div style={chartContainerStyle}>
                            {isCompStatusBar ? (        
                            <BarChart 
                                comparisonData={comparisonData} 
                                attribute={'status'} 
                                selectedUseCase={selectedUseCase} 
                                handleChartClick={handleChartClick}
                                handleChartHover={handleChartHover}
                            />     
                            ) : (
                            <PieChart comparisonData={comparisonData} attribute={'status'} />
                            )}
                        </div>
                    </Col>                                               
                </Row>
            </Col>
            {/* Use Case Insights - Actor and Component Analysis */}
            <Col md={12}>
                {/* Use Case Data: List of actors per category */}
                <Row style={ { marginBottom:'20px'} }>                                
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center" style={ { marginBottom:'15px' } }>
                            <h5>List of actors</h5>
                        <div>
                            <OverlayTrigger placement="top" overlay={<Tooltip>High-level component overview</Tooltip>}>
                                <Button variant="outline-primary" className={`btn-sm ${isActorBar ? 'active' : ''}`} style={ { marginLeft:'10px' } } onClick={handleActorBar} disabled={isActorBar}>                            
                                    <i className="bi bi-bar-chart"></i>                            
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Component overview in table format</Tooltip>}>
                                <Button variant="outline-primary" className={`btn-sm ${isActorTable ? 'active' : ''}`} style={ { marginLeft:'10px' } } onClick={handleActorTable} disabled={isActorTable}>
                                        <i className="bi bi-table"></i>
                                </Button>
                            </OverlayTrigger>
                            </div>
                        </div>
                        {isActorBar && <ActorBarChart list={selectedUseCase.actors.value.list} handleChartClick={handleChartClickAC} handleChartHover={handleChartHover} />}
                        {isActorTable && <ActorListTable list={selectedUseCase.actors.value.list} />}
                    </Col>
                </Row>
                {/* Use Case Data: Node centrality */}
                <Row style={ { marginBottom:'20px'} }>
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center" style={ { marginBottom:'15px' } }>
                            <h5>Node Centrality and Top Connected Nodes</h5>
                            <div>
                            <OverlayTrigger placement="top" overlay={<Tooltip>High-level component overview</Tooltip>}>
                                <Button variant="outline-primary" className={`btn-sm ${isActorNodeCentral ? 'active' : ''}`} style={ { marginLeft:'10px' } } onClick={handleActorNodeCentral} disabled={isActorNodeCentral}>                            
                                    <i className="bi bi-align-center"></i>                            
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Component overview in table format</Tooltip>}>
                                <Button variant="outline-primary" className={`btn-sm ${isActorTopConnected ? 'active' : ''}`} style={ { marginLeft:'10px' } } onClick={handleActorTopConnected} disabled={isActorTopConnected}>
                                        <i className="bi bi-1-circle"></i>
                                </Button>
                            </OverlayTrigger>
                            </div>
                        </div>
                        {isActorNodeCentral && <NodeCentrality nodes={selectedUseCase.actors.value.nodes} edges={selectedUseCase.actors.value.edges} /> }
                        {isActorTopConnected && <TopConnectedNodes nodes={selectedUseCase.actors.value.nodes} edges={selectedUseCase.actors.value.edges} /> }
                    </Col>
                </Row>
                <Row>
                    {/* Use Case Data: List of components per category */}
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center" style={ { marginBottom:'15px' } }>
                            <h5>Component analysis</h5>
                            <div>
                                <OverlayTrigger placement="top" overlay={<Tooltip>High-level component overview</Tooltip>}>
                                <Button variant="outline-primary" className={`btn-sm ${isComponentsBar ? 'active' : ''}`} style={ { marginLeft:'10px' } } onClick={handleComponentBar} disabled={isComponentsBar}>                            
                                    <i className="bi bi-bar-chart"></i>                            
                                </Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={<Tooltip>Component overview with detailed categories</Tooltip>}>
                                <Button variant="outline-primary" className={`btn-sm ${isComponentsBarNested ? 'active' : ''}`} style={ { marginLeft:'10px' } } onClick={handleComponentBarNested} disabled={isComponentsBarNested}>                            
                                    <i className="bi bi-file-bar-graph"></i>                                
                                </Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={<Tooltip>Component overview in table format</Tooltip>}>
                                <Button variant="outline-primary" className={`btn-sm ${isComponentsTable ? 'active' : ''}`} style={ { marginLeft:'10px' } } onClick={handleComponentTable} disabled={isComponentsTable}>                            
                                    <i className="bi bi-table"></i>
                                </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        {isComponentsBar && <ComponentBarChart components={selectedUseCase.components.value} handleChartClick={handleChartClickAC} handleChartHover={handleChartHover} />}
                        {isComponentsBarNested && <ComponentBarChartNested components={selectedUseCase.components.value} handleChartClick={handleChartClickAC} handleChartHover={handleChartHover} />}
                        {isComponentsTable && <ComponentListTable components={selectedUseCase.components.value} />}                                   
                    </Col>
                </Row>     
                {/* Use Case Data: compare actor and components */}       
                <Row style={ { marginBottom:'20px'} }>
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center" style={ { marginBottom:'15px' } }>
                            <h5>Comparison of Actors and Components</h5>
                        </div>
                        <Row style={ { marginBottom:'20px'} }>         
                            {/* Actors Section */}   
                            <Col md={6}>         
                                <h5>Actors</h5>         
                                <ActorView nodes={selectedUseCase.actors.value.nodes} expandedCategory={expandedCategory} onToggle={handleToggle} />
                            </Col>
                            {/* Components Section */}  
                            <Col md={6}>     
                                <h5>Components</h5>                           
                                <ComponentView components={selectedUseCase.components.value} expandedCategory={expandedCategory} onToggle={handleToggle} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>     
            <MatchingScoreModal show={showModalScore} handleClose={() => setShowModalScore(false)} />                       
        </Row>
    );
}