/**
 * Describes the procedure for the user study
 *
 * Description: Provides the elements
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-15
 * Notes: /
 */

import React, { useState } from 'react';

import { Form, Button, Container, Row } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

import { ElemToast } from '../components/elems/elem_toast';

export default function Participate(props) {

    const navigate = useNavigate();

    const [showToast, setShowToast] = useState(false);
    const toggleToast = () => setShowToast(!showToast);
    const label = 'Please agree to the consent form to proceed. This way we are able to integrate the use case in our research study. Thank you.'

    const [agreed, setAgreed] = useState(false);

    const handleAgreementChange = () => {
        setAgreed(!agreed);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (agreed) {
            navigate('/Instructions');
        } else {
            setShowToast(true);     
        }
    };

    return (
        <Container fluid style={ { maxWidth: '700px' } }>
            {/* Intro */}
            <Row align='center'>      
                <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Participate</b></h1>
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                    <i>ucm</i> is designed to support research in the field of Ontology-Driven Use Case Mapping and with emphasis on Autonomous Mobility in the Platform Economy.
                </p>
            </Row>
            {/* Context and Purpose */}
            <Row align='center'>
                <h3 style={{marginTop: '30px', marginBottom: '30px'}}>Context and Purpose</h3>
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>      
                    Participate in this study to help create Autonomous Mobility and Transportation use cases based on the 
                    developed <a href="https://marc-ga.github.io/peamonto/guco.owl">GUCO</a> and <a href="https://marc-ga.github.io/peamonto/peamonto.owl">PEAMONTO</a> 
                    ontologies, supported by the <i>UCM Association Guide</i>.
                    The study takes approximately 30 minutes.
                </p>
      
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                    The data collected from the created use cases serve as the basis to analyze and propose a Platform Economy Reference Model for Autonomous Mobility.
                    This page guides you through the study, helping you to create an understanding about your envisioned use case.
                </p>
            </Row>      
            {/* Consent Form */}            
            <Row align='center'>
                <h3 style={{marginTop: '30px', marginBottom: '30px'}}>Accessibility and Consent</h3>
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                    The data acquired is made available in the UCM repository for you and everyone to learn and further development. You choose what data should remain private.
                </p>
                <div className="d-flex justify-content-center"> {/* Center aligning container */}
                    <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="consentCheckbox">
                        <Form.Check 
                        type="checkbox" 
                        label={
                            <>
                                I agree to the&nbsp;
                                <span
                                    role="button"
                                    onClick={() => alert('Participant Consent Form')}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Necessary to allow us evaluate the data"
                                    style={{
                                    color: 'blue',
                                    cursor: 'pointer',
                                    textDecoration: 'underline'
                                    }}
                                >
                                    Participant Consent Form
                                </span>
                            </>
                        } 
                        checked={agreed} 
                        onChange={handleAgreementChange} 
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">Start Study</Button>
                    </Form> 
                </div>
            </Row>
            
            {/* show toast */}
            {ElemToast(showToast, toggleToast, label)}
        
        </Container>
    );
};

export { default as Participate } from './Participate';