/**
 * Entry page for the UCM tool. Contains a brief introduction to the tool and its main functions.
 *
 * Description:
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-24 (last modified 2024-07-06)
 * 
 */

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import tool from '../assets/img/20240414_peccam.png';
import peamonto_vis from '../assets/img/20240312_peamonto_vis_car.png';
import uc_module from '../assets/img/20240310_UC_Module.png';
import mapper from '../assets/img/20240609_Ecosystem.png';
import creator from '../assets/img/20240609_Creator.png';
import repository from '../assets/img/20240609_Repository.png';

import Papierboot from '../assets/img/Papierboot.png';

import { fetchUseCaseData } from '../components/db_mgmt/read';

import { StatisticsDashboard, computeEcoStats } from '../components/eco_analytics_handling/eco_stats';

import ModalCreateMode from '../components/create_handling/create_modal_mode';

import UseCaseMap from '../components/elems/elem_map';

const KnowledgeRepresentationsLayout = ( { navigateKB } ) => {

  const items = [
    {
      id: 1,
      title: 'GUCO',
      img: uc_module,
      content: (
        <>
          <h3 className="card-title" style={{marginBottom:'15px'}}><i>GUCO</i> knowledge representation</h3>
          <p className="card-text">
            Explore the <i>Generic Use Case Ontology</i> developed to represent use cases of any domain.
            GUCO is modeled in OWL and provides a structured representation of use case elements. It provides a frame for describing use cases in a structured way.                  
          </p>
          <p className="card-text">
            Any domain ontology can be linked to GUCO to provide a structured representation of use cases in that domain. Also, private linked data can be used to enrich the representation.
          </p>
          <p className="card-text"><small className="text-body-secondary">OWL code can be retrieved on Github</small></p>
          <div>
            <hr></hr>
            <Button variant="outline-primary" onClick={navigateKB}>explore</Button>
          </div>
        </>
      ),
      image: 'https://via.placeholder.com/150', // Replace with actual image path
    },
    {
      id: 2,
      title: 'PEAMONTO',
      img: peamonto_vis,
      content: (
        <>
          <h3 className="card-title" style={{marginBottom:'15px'}}><i>PEAMONTO</i> domain knowledge representation</h3>
          <p className="card-text">
            Explore the <i>PE-AM Ontology</i> elements, constituting the concepts DAI, CCAM and PE.
            PEAMONTO is modeled in OWL and provides a structured representation of autonomous mobility domain knowledge.                  
          </p>
          <p className="card-text">
            PEAMONTO is designed to represent the concepts of Data, Communication and Processing Elements in the context of autonomous mobility.
          </p>
          <p className="card-text"><small className="text-body-secondary">OWL code can be retrieved on Github</small></p>
          <div>
            <hr></hr>
            <Button variant="outline-primary" onClick={navigateKB}>explore</Button>
          </div>
        </>
      ),
      image: 'https://via.placeholder.com/150', // Replace with actual image path
    },
    {
      id: 3,
      title: 'Other Ontologies',
      img: Papierboot,
      content: (
        <>
          <h3 className="card-title" style={{marginBottom:'15px'}}><i>Semantic Model</i> knowledge representations</h3>
          <p className="card-text">
            Import a knowledge representation and attach it to UCM in order to enrich the use case model with domain knowledge.
          </p>
          <p className="card-text">
            Any domain ontology can be linked to GUCO to provide a structured representation of use cases in that domain. Also, private linked data can be used to enrich the representation.
          </p>
          <p className="card-text"><small className="text-body-secondary">OWL code can be retrieved on Github</small></p>
          <div>
            <hr></hr>
            <Button variant="outline-primary" onClick={navigateKB}>explore</Button>
          </div>
        </>
      ),
      image: 'https://via.placeholder.com/150', // Replace with actual image path
    },
  ];

  const [activeItem, setActiveItem] = useState(items[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveItem((prevItem) => {
        const currentIndex = items.findIndex(item => item.id === prevItem.id);
        const nextIndex = (currentIndex + 1) % items.length;
        return items[nextIndex];
      });
    }, 5000); // Change item every 5 seconds

    return () => clearInterval(interval);
  });

  const handleClick = (item) => {
    setActiveItem(item);
  };

  return (
    <div>
      {/* Layout with image, bar, and content */}
      <Row className="align-items-center justify-content-center" style={{ marginTop: '50px', minHeight: '400px' }}>
        {/* Left Side (Image) */}
        <Col md={4} className="d-flex justify-content-center">
          <img src={activeItem.img} className="img-fluid rounded-start" alt={activeItem.title} />
        </Col>

        {/* Middle Bar */}
        <Col md={1} className="d-flex flex-column align-items-center">
          {items.map((item) => (
            <div
              key={item.id}
              onClick={() => handleClick(item)}
              style={{
                cursor: 'pointer',
                width: item.id === activeItem.id ? '15px' : '5px',
                height: '50px',
                backgroundColor: item.id === activeItem.id ? '#0d6efd' : 'gray',
                marginBottom: '10px',
              }}
            />
          ))}
        </Col>

        {/* Right Side (Content) */}
        <Col md={7} className="d-flex flex-column justify-content-center">
          {activeItem.content}
        </Col>
      </Row>
    </div>
  );
};

export default function UCM(props) {

  const [useCases, setUseCases] = useState([]);

  const [showModalCreateMode, setShowModalCreateMode] = useState(false);

  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchUseCaseData();
      setUseCases(data);
    };

    getData();        
  }, []);

  const stats = computeEcoStats(useCases);

  const navigate = useNavigate();

  // repository, dashboard
  const navigateRepository = () => {
    navigate('/Repository');
  };

  // creator, cockpit
  /*
  const navigateCockpit = () => {
    navigate('/Create');
  };
  */

  const handleCreateMode = () => {
    setShowModalCreateMode(true);
  }

  // viewer, analytics
  const navigateEcoMapper = () => {
    //navigate('/Eco_Mapper');
    navigate('/Analytics');
  };

  const navigateKB = () => {
    navigate('/KB');
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const getCardContent = (card) => {
    switch (card) {
        case 'scenarioBuilder':
            return "Use the scenario builder to define a comprehensive set of actions.";
        case 'actorModeler':
            return "Model all actors involved for a specific project, use case or ecosystem you are looking at.";
        case 'componentArchitect':
            return "Identify technical components for conceptualizing and implementing a use case.";
        case 'ucAnalysis':
          return "Look up a use case's composition or analyze how it performs in the larger ecosystem";
        case 'bpmnBuilder':
          return "Model processes of use cases at hand; best combined with the Scenario Builder.";
        case 'reporting':
          return "Generate comprehensive reports of modeled use cases and ecosystems";
        default:
            return "";
    }
  };

  return (
    <div className="container-fluid">    
      <Container fluid>
        {/* Intro */}
        <Row className="justify-content-center" align='center' style={ { marginTop:'100px' } }>
          <Col md={1}></Col>
          <Col md={5} align='left' style={ { maxWidth: '700px' } }>

            <h1 className="display-4 display-md-3" style={ { marginBottom: '30px' } }><b>Use Case-Driven Solution Builder</b></h1>
            <p style={ { margin: '0 auto', marginBottom: '10px', fontSize: '20px' } }>
            <i>UCM</i> is a tool to explore components, data and actors to conceptualize solutions, using semantic models for use case creation and ecosystem analysis.
            </p>

            <div style={ { marginTop:'50px'} }>
              <Button className="fancy-button" variant="primary" style={{ marginRight: '10px', minWidth:'300px', minHeight:'80px', fontSize:'20px' }} onClick={handleCreateMode}>
                <i className="bi bi-pencil-square"></i> <b>Create a Use Case</b>
              </Button>
              {/*
              <Button variant="outline-primary" style={{ marginRight: '10px' }} onClick={navigateRepository}>
                <i className="bi bi-database"></i> Repository
              </Button>
              <Button variant="outline-primary" onClick={navigateEcoMapper}>
                <i className="bi bi-layers"></i> Analytics
              </Button>
              */}

            </div>

          </Col>
          <Col md={5} style={ { maxWidth: '700px' } }>
          <div>
            <img 
              src={tool} 
              alt="tool" 
              style={{ 
                width: '100%', 
                margin: '20px auto', 
                border: '1px solid #052c65', 
                borderRadius: '5%', 
                padding: '20px',
                boxShadow: '0 0 5px #052c65'  // #0d6efd
              }} 
            />
          </div>
          </Col>    
          <Col md={1}></Col>               
        </Row>
        
        <div style={{ marginTop: '100px' }}>
          <hr style={{ maxWidth: '1000px', margin: '0 auto', borderColor: '#0d6efd' }} />
        </div>

        {/* Statistics Mini Dashboard */}
        <Row align='center' style={{ marginTop: '100px' }}>
          <h1><b>Use Case Dashboard</b></h1>
          <h5 style={{ marginTop: '5px', marginBottom: '30px' }}>Access to the UCM Knowledge Base</h5>
        </Row>
        <Row align='center' style={ { marginTop:'50px'} }>
          <Col md={1}></Col>
          <Col md={10}>
            <StatisticsDashboard stats={stats} />
          </Col>
          <Col md={1}></Col>
        </Row>
        <Row align='center' style={{ marginTop: '100px' }}>
          <Col md={1}></Col>
          <Col md={10}>
            <UseCaseMap useCases={useCases} />
          </Col>
          <Col md={1}></Col>
        </Row>

        <div style={{ marginTop: '100px' }}>
          <hr style={{ maxWidth: '1000px', margin: '0 auto', borderColor: '#0d6efd' }} />
        </div>

        {/* Functions */}
        <Row align='center' style={{ marginTop: '100px' }}>
          <h1><b>From Use Case Modeling to Ecosystem Analysis</b></h1>
          <h5 style={{marginTop: '5px', marginBottom: '30px'}}>Explore the Repository, start modeling or map a use case in the Ecosystem</h5>
          <Col md={1}></Col>
          <Col md={10}>
            {/* Creator */}
            <Row align='left' style={{ marginTop: '50px' }}>
              <Col md={5}>
                <img 
                  src={creator} 
                  alt="creator" 
                  style={{ 
                    maxHeight: '350px',
                    width: '100%', 
                    margin: '20px auto', 
                    border: '1px solid #052c65', 
                    borderRadius: '1%', 
                    boxShadow: '0 0 5px #052c65',
                    padding: '20px',
                    objectFit: 'contain'
                  }} 
                />
                {/*<img src={repository} className="card-img-top" alt="..." style={{ maxHeight: '200px', width: '40%', objectFit: 'cover' }}/>*/}
              </Col>
              <Col md={7}>  
                <Card.Body style={{ height: '100%' }}>
                <div style={{ marginLeft: '10px' }}>
                  <img src={Papierboot} width="70" height="70" alt="" />
                  <h1 className="display-4 display-md-3" style={ { marginBottom: '30px' } }><b>Creator</b></h1>
                  <p style={ { margin: '0 auto', marginBottom: '10px', fontSize: '20px' } }>
                    Model a use case in your domain.
                    UCM guides you through a unique step-by-step approach to create a use case model.
                    You choose whether to start from scratch or to use an existing model.
                    There are three creation modes: manual, from template or using the UCM co-pilot.
                  </p>
                  <Button variant="outline-primary" onClick={handleCreateMode} style={ { marginTop:'30px'} }>
                    <i className="bi bi-pencil-square"></i> Start creating
                  </Button>
                </div>             
                </Card.Body>                                        
              </Col>
            </Row>      

            {/* Repo */}
            <Row align='left' style={{ marginTop: '50px' }}>
              <Col md={7}>  
                <Card.Body style={{ height: '100%' }}>
                <div style={{ marginLeft: '10px' }}>
                  <img src={Papierboot} width="70" height="70" alt="" />
                  <h1 className="display-4 display-md-3" style={ { marginBottom: '30px' } }><b>Repository</b></h1>
                  <p style={ { margin: '0 auto', marginBottom: '10px', fontSize: '20px' } }>
                    Explore the use case repository to retrieve, inspect or build on existing use cases.
                    The repository provides a structured view of modeled use cases, including their components, actors and data.
                  </p>
                  <Button variant="outline-primary" onClick={navigateRepository} style={ { marginTop:'30px'} }>
                    <i className="bi bi-database"></i> Discover use cases
                  </Button>
                </div>             
                </Card.Body>                                        
              </Col>
              <Col md={5}>
                <img 
                  src={repository} 
                  alt="repository" 
                  style={{ 
                    maxHeight: '350px',
                    width: '100%', 
                    margin: '20px auto', 
                    border: '1px solid #052c65', 
                    borderRadius: '1%', 
                    boxShadow: '0 0 5px #052c65',
                    padding: '20px',
                    objectFit: 'contain'
                  }} 
                />
                {/*<img src={repository} className="card-img-top" alt="..." style={{ maxHeight: '200px', width: '40%', objectFit: 'cover' }}/>*/}
              </Col>              
            </Row>   

            {/* Analytics */}
            <Row align='left' style={{ marginTop: '50px' }}>
              <Col md={5}>
                <img 
                  src={mapper} 
                  alt="mapper" 
                  style={{ 
                    maxHeight: '350px',
                    width: '100%', 
                    margin: '20px auto', 
                    border: '1px solid #052c65', 
                    borderRadius: '1%', 
                    boxShadow: '0 0 5px #052c65',
                    padding: '20px',
                    objectFit: 'contain'
                  }} 
                />              
              </Col>
              <Col md={7}>  
                <Card.Body style={{ height: '100%' }}>
                <div style={{ marginLeft: '10px' }}>
                  <img src={Papierboot} width="70" height="70" alt="" />
                  <h1 className="display-4 display-md-3" style={ { marginBottom: '30px' } }><b>Analytics</b></h1>
                  <p style={ { margin: '0 auto', marginBottom: '10px', fontSize: '20px' } }>
                    UCM equips you with tools to analyze the impact of a use case in the ecosystem.
                    It addresses actors, components and data, and provides insights on the use case's role in the ecosystem.
                    Also, it provides you with tools to build processes and create reports.
                  </p>
                  <Button variant="outline-primary" onClick={navigateEcoMapper} style={ { marginTop:'30px'} }>
                    <i className="bi bi-pencil-square"></i> Analyze impact
                  </Button>
                </div>             
                </Card.Body>                                        
              </Col>
            </Row>    

          </Col>
          <Col md={1}></Col>
        </Row>      

        <div style={{ marginTop: '100px' }}>
          <hr style={{ maxWidth: '1000px', margin: '0 auto', borderColor: '#0d6efd' }} />
        </div>

        {/* UCM Tools */}
        <Row align='center'>
          <h1 style={ { marginTop: '100px' } }><b>UCM Tools</b></h1>
          <h5 style={{marginTop: '5px', marginBottom: '80px'}}>Develop a dedicated snippet of your use case</h5>
        </Row>

        <Row align='center' className="d-flex justify-content-center">
          {/* Scenario Builder */}
          <Col md={1}></Col>
          <Col className="d-flex">
              <Card 
                  className={`text-center mb-3 flex-fill w-100 ${selectedCard === 'scenarioBuilder' ? 'bg-primary text-white' : ''}`} 
                  style={{ cursor: 'pointer' }} 
                  onClick={() => handleCardClick('scenarioBuilder')}
              >
                  <Row>
                      <Col>  
                          <Card.Body>
                          <i className="bi bi-stack-overflow" style={{ fontSize: '4rem' }}></i>
                          <Card.Title>Scenario Builder</Card.Title>
                          </Card.Body>
                      </Col>
                      {selectedCard === 'scenarioBuilder' && (
                      <Col>
                          <Card.Body align='right'>
                              <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                  <i className="bi bi-info-circle"></i> {getCardContent(selectedCard)}
                              </Card.Text>
                              <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Use the scenario builder to define a comprehensive set of actions, flows, pre- and postconditions, assumptions and constraints for a use case</Tooltip>}>
                              <Button variant="light" size="sm" disabled>
                                  <i className="bi bi-arrow-right"></i> Model scenarios
                              </Button>
                              </OverlayTrigger>
                          </Card.Body>
                      </Col>
                      )}
                  </Row>
              </Card>
          </Col>
          {/* Actor Modeler */}
          <Col className="d-flex">
              <Card 
                  className={`text-center mb-3 flex-fill w-100 ${selectedCard === 'actorModeler' ? 'bg-primary text-white' : ''}`} 
                  style={{ cursor: 'pointer' }} 
                  onClick={() => handleCardClick('actorModeler')}
              >
                  <Row>
                      <Col>
                          <Card.Body>
                              <i className="bi bi-vignette" style={{ fontSize: '4rem' }}></i>
                              <Card.Title>Actor Modeler</Card.Title>
                          </Card.Body>
                      </Col>
                      {selectedCard === 'actorModeler' && (
                      <Col>
                          <Card.Body align='right'>
                              <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                  <i className="bi bi-info-circle"></i> {getCardContent(selectedCard)}
                              </Card.Text>
                              <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Model all actors involved in your use case</Tooltip>}>
                              <Button variant="light" size="sm" disabled>
                                  <i className="bi bi-arrow-right"></i> Model Actors
                              </Button>
                              </OverlayTrigger>
                          </Card.Body>
                      </Col>
                      )}
                  </Row>
              </Card>
          </Col>
          {/* Component Architecture */}
          <Col className="d-flex">
              <Card 
                  className={`text-center mb-3 flex-fill w-100 ${selectedCard === 'componentArchitect' ? 'bg-primary text-white' : ''}`} 
                  style={{ cursor: 'pointer' }} 
                  onClick={() => handleCardClick('componentArchitect')}
              >
                  <Row>
                      <Col>
                          <Card.Body>
                          <i className="bi bi-cpu" style={{ fontSize: '4rem' }}></i>
                          <Card.Title>Component Architect</Card.Title>
                          </Card.Body>
                      </Col>
                      {selectedCard === 'componentArchitect' && (
                      <Col>
                          <Card.Body align='right'>
                              <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                  <i className="bi bi-info-circle"></i> {getCardContent(selectedCard)}
                              </Card.Text>
                              <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Identify technical components for conceptualizing and implementing a use case</Tooltip>}>
                              <Button variant="light" size="sm" disabled>
                                  <i className="bi bi-arrow-right"></i> Create archictecture
                              </Button>
                              </OverlayTrigger>
                          </Card.Body>
                      </Col>
                      )}
                  </Row>
              </Card>
          </Col>
          <Col md={1}></Col>
        </Row>

        <Row align='center' className="d-flex justify-content-center">
          {/* UC Analysis */}
          <Col md={1}></Col>
          <Col className="d-flex">
              <Card 
                  className={`text-center mb-3 flex-fill w-100 ${selectedCard === 'ucAnalysis' ? 'bg-primary text-white' : ''}`} 
                  style={{ cursor: 'pointer' }} 
                  onClick={() => handleCardClick('ucAnalysis')}
              >
                  <Row>
                      <Col>  
                          <Card.Body>
                          <i className="bi bi-bar-chart" style={{ fontSize: '4rem' }}></i>
                          <Card.Title>Use Case Analysis</Card.Title>
                          </Card.Body>
                      </Col>
                      {selectedCard === 'ucAnalysis' && (
                      <Col>
                          <Card.Body align='right'>
                              <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                  <i className="bi bi-info-circle"></i> {getCardContent(selectedCard)}
                              </Card.Text>
                              <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">...</Tooltip>}>
                              <Button variant="light" size="sm" disabled>
                                  <i className="bi bi-arrow-right"></i> Start Analysis
                              </Button>
                              </OverlayTrigger>
                          </Card.Body>
                      </Col>
                      )}
                  </Row>
              </Card>
          </Col>
          {/* BPMN Builder */}
          <Col className="d-flex">
              <Card 
                  className={`text-center mb-3 flex-fill w-100 ${selectedCard === 'bpmnBuilder' ? 'bg-primary text-white' : ''}`} 
                  style={{ cursor: 'pointer' }} 
                  onClick={() => handleCardClick('bpmnBuilder')}
              >
                  <Row>
                      <Col>
                          <Card.Body>
                            <i className="bi bi-journal-arrow-up" style={{ fontSize: '4rem' }}></i>
                            <Card.Title>BPMN Builder</Card.Title>
                          </Card.Body>
                      </Col>
                      {selectedCard === 'bpmnBuilder' && (
                      <Col>
                          <Card.Body align='right'>
                              <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                  <i className="bi bi-info-circle"></i> {getCardContent(selectedCard)}
                              </Card.Text>
                              <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Identify technical components for conceptualizing and implementing a use case</Tooltip>}>
                              <Button variant="light" size="sm" disabled>
                                  <i className="bi bi-arrow-right"></i> Model BPMN
                              </Button>
                              </OverlayTrigger>
                          </Card.Body>
                      </Col>
                      )}
                  </Row>
              </Card>
          </Col>
          {/* Reporting */}
          <Col className="d-flex">
              <Card 
                  className={`text-center mb-3 flex-fill w-100 ${selectedCard === 'reporting' ? 'bg-primary text-white' : ''}`} 
                  style={{ cursor: 'pointer' }} 
                  onClick={() => handleCardClick('reporting')}
              >
                  <Row>
                      <Col>
                          <Card.Body>
                          <i className="bi bi-file-earmark-pdf" style={{ fontSize: '4rem' }}></i>
                          <Card.Title>Reporting</Card.Title>
                          </Card.Body>
                      </Col>
                      {selectedCard === 'reporting' && (
                      <Col>
                          <Card.Body align='right'>
                              <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                  <i className="bi bi-info-circle"></i> {getCardContent(selectedCard)}
                              </Card.Text>
                              <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Identify technical components for conceptualizing and implementing a use case</Tooltip>}>
                              <Button variant="light" size="sm" disabled>
                                  <i className="bi bi-arrow-right"></i> Generate Report
                              </Button>
                              </OverlayTrigger>
                          </Card.Body>
                      </Col>
                      )}
                  </Row>
              </Card>
          </Col>
          <Col md={1}></Col>
        </Row>

        <div style={{ marginTop: '100px' }}>
          <hr style={{ maxWidth: '1000px', margin: '0 auto', borderColor: '#0d6efd' }} />
        </div>

        {/* Intro models */}
        <Row align='center'>
          <h1 style={ { marginTop: '100px' } }><b>Semantic Models for Ecosystem Analysis</b></h1>
          <h5 style={{marginTop: '5px', marginBottom: '30px'}}>Leveraging Use Case Mapping and Ontology Development</h5>
        </Row>
        {/* Carousel to introduce the models */}
        <Row>
          {/*<KnowledgeRepresentationsCarousel navigateApproach={navigateApproach} navigateGUCO={navigateGUCO} />*/}
          <Col md={1}></Col>
          <Col>
            <KnowledgeRepresentationsLayout navigateKB={navigateKB} />
          </Col>
          <Col md={1}></Col>
        </Row> 
          
        <div style={{ marginTop: '100px' }}>         
        </div>

        {/* Navigate 
        <Row align='center' className="justify-content-center">
          <Col>
            <img src={Papierboot} className="img-fluid" alt="Responsive" style={{ width: '20%', height: 'auto' }}/>        
            <Button variant="primary" style={{ marginRight: '10px' }} onClick={navigateCockpit}>
              <i className="bi bi-pencil-square"></i> Creator
            </Button>
            <Button variant="outline-primary" style={{ marginRight: '10px' }} onClick={navigateRepository}>
              <i className="bi bi-database"></i> Repository
            </Button>          
          </Col>
        </Row>      
        */}
        
      </Container>      
      {/* Modal for Create Mode */}
      {showModalCreateMode && (
        <ModalCreateMode 
          show={showModalCreateMode} 
          onHide={() => setShowModalCreateMode(false)}         
        />  
      )}
    </div>
  );
};

export { default as UCM } from './UCM';