/**
 * Prompt page
 *
 * Description: This module contains functions to generate prompts for the chat.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-23 (last updated on June 30,2024)
 * 
 */


import React, { useState } from 'react';
import { Container, Button, Row, Tooltip, OverlayTrigger, Col, Tab, Accordion, Badge, Nav } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

import InfoModal from '../components/elems/elem_info_modal';
  
const CustomNav = () => {
    const [activeKey, setActiveKey] = useState('1');

    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);
    };

    const step_names = ['Motivation', 'Problem statement', 'SoTa', 'Approach to PS', 'Methodology', 'Findings', 'Current status'];

    return (
        <Nav
        className="flex-column me-3"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
        style={{ textAlign: 'left', width: '250px' }}
        activeKey={activeKey}
        onSelect={handleSelect}
        >
        {['1', '2', '3', '4', '5', '6', '7'].map((step, index) => (
            <Nav.Item className="mb-2" key={step}>
                <Nav.Link
                    eventKey={step}
                    style={{
                    border: activeKey === step ? '2px solid #007bff' : '2px solid transparent',
                    borderRadius: '5px',
                    }}
                >
                    {index + 1}. {step_names[index]}
                </Nav.Link>
            </Nav.Item>
        ))}
        </Nav>
    );
};
  
const AccordionComponent = ({ step_name, description, status, paper, scheduled, review, content }) => (
<Accordion defaultActiveKey="1">

    <Accordion.Item eventKey="1">
        <Accordion.Header>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>            
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <Badge bg="info" text="dark" style={{ marginRight: '10px' }}>{step_name}</Badge>
            <span style={{ marginRight: '10px' }}>{description}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginRight:'15px' }}>
            <Badge bg="success" text="light" style={{ marginLeft: 'auto' }}>Status: {status}</Badge>
            <Badge bg="secondary" text="light" style={{ marginLeft: '5px' }}>Paper: {paper}</Badge>
            <Badge bg="secondary" text="light" style={{ marginLeft: '5px' }}>Scheduled: {scheduled}</Badge>
            <Badge bg="secondary" text="light" style={{ marginLeft: '5px' }}>Validate: {review}</Badge>
            </div>
        </div>
        </Accordion.Header>
        <Accordion.Body>
            {content}
        </Accordion.Body>
    </Accordion.Item>

</Accordion>
);

const DetailsApproach = () => (
    <Tab.Container id="v-pills-tab" defaultActiveKey="1">
    <div className="d-flex align-items-start">
        <CustomNav />
        <div style={{ width: '100%' }}>
        <Tab.Content>
            <Tab.Pane eventKey="1">
                <AccordionComponent 
                    step_name='Scope'
                    description='Platform Economy for Autonomous Mobility Reference Model'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <p>
                        The reference model is a comprehensive framework for the development of platform-based solutions in the context of autonomous mobility. 
                        It includes the identification of the main actors, components, and interactions, as well as the classification of the involved entities, 
                        means of transport, and transport modes. The model is based on the Distributed Artificial Intelligence (DAI) approach and is designed to 
                        support the development of Cooperative, Connected, and Automated Mobility (CCAM) solutions.
                        </p>
                    }
                />            
            </Tab.Pane>
            <Tab.Pane eventKey="2">
                <AccordionComponent
                    step_name='Addressed'
                    description='Pose a model for AM use cases in the PE'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <p>
                            The model is based on the Distributed Artificial Intelligence (DAI) approach and is designed to support the development of Cooperative,
                            Connected, and Automated Mobility (CCAM) solutions. It provides a framework for building DAI-based CCAM solutions in the PE and comprises
                            the creation of actor maps, identification of the involved entities, means of transport and transport mode, assuming different degrees of
                            digitalization, data points and flows across entities as well as platform economy principles within which such a solution is placed in.
                        </p>
                    }
                />                    
            </Tab.Pane>
            {/* SoTa */}
            <Tab.Pane eventKey="3">
                <AccordionComponent
                    step_name='Classification'
                    description='Theoretical Background of the Research'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <div>
                        <p>
                            ... reference to the theoretical background of the research ...                           
                        </p>
                        <p>
                            What is the status in modeling use cases?
                            What is the role of prompt engineering and LLMs? what current work exists
                            in the field of prompt engineering and LLMs?
                        </p>
                        </div>
                    } 
                />
            </Tab.Pane>
            {/* Approach to PS */}
            <Tab.Pane eventKey="4">
                <AccordionComponent
                    step_name='Approach'
                    description='Use Case Engineering and Qualitative Inquiry'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <p>
                            The tool is based on the Distributed Artificial Intelligence (DAI) approach and is designed to support the development of Cooperative, 
                            Connected and Automated Mobility (CCAM) solutions.

                            It provides a framework for building DAI-based CCAM solutions in the PE and comprises the creation of actor maps, identification of the involved entities, 
                            means of transport and transport mode, assuming different degrees of digitalization, data points and flows across entities as well as platform economy 
                            principles within which such a solution is placed in.
                            <br />

                            Describe the developed approach; use a chart/figure to visualize the process.
                        </p>
                    } 
                />
            </Tab.Pane>
            {/* Methodology */}
            <Tab.Pane eventKey="5">
                <AccordionComponent 
                    step_name='Method'
                    description='UCM Use Case Engineering, Analysis and Qualitative Inquiry'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <div>
                        <p>
                            The tool is based on the Distributed Artificial Intelligence (DAI) approach and is designed to support the development of Cooperative, 
                            Connected and Automated Mobility (CCAM) solutions.

                            It provides a framework for building DAI-based CCAM solutions in the PE and comprises the creation of actor maps, identification of the involved entities, 
                            means of transport and transport mode, assuming different degrees of digitalization, data points and flows across entities as well as platform economy 
                            principles within which such a solution is placed in.
                        </p>
                        <p>
                            Develop a survey page as reference; questions are: 1) how easy did you find the process for creating a use case; 
                            2) what is your domain of expertise? 3) how valuable in general do you find identifying/framing use cases? 
                            4) with regards to the use case mapper: how would you classify it: helpful, not helpful; 
                            5) how helpful were the suggested components? In what areas did it help to model a case? 
                            6) do you think it could be helpful in some of your use case modeling activities? 7) what is missing? 
                            8) what would be helpful? 
                            9) are you satifified with the result given by the matter? 
                            10) did the modeled components help you further develop your case?
                        </p>
                        <p>
                            Ask users about the tool: 
                            1) how easy did you find the process for creating a use case; <br />
                            2) what is your domain of expertise? <br />
                            3) how valuable in general do you find identifying/framing use cases? <br />
                            4) with regards to the use case mapper: how would you classify it: helpful, not helpful; <br />
                            5) how helpful were the suggested components? In what areas did it help to model a case? <br />
                            6) do you think it could be helpful in some of your use case modeling activities? <br />
                            7) what is missing? <br />
                            8) what would be helpful? <br />
                            9) are you satifified with the result given by the matter? <br />
                            10) did the modeled components help you further develop your case?

                            General about use case engineering
                            Then about the tool and how it addresses it
                            Then about the specific use case modeled in the session
                        </p>
                        </div>
                    }
                />
            </Tab.Pane>
            <Tab.Pane eventKey="6">
                <AccordionComponent 
                    step_name='Method'
                    description='UCM Use Case Engineering, Analysis and Qualitative Inquiry'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <p>
                            The tool is based on the Distributed Artificial Intelligence (DAI) approach and is designed to support the development of Cooperative, 
                            Connected and Automated Mobility (CCAM) solutions.

                            It provides a framework for building DAI-based CCAM solutions in the PE and comprises the creation of actor maps, identification of the involved entities, 
                            means of transport and transport mode, assuming different degrees of digitalization, data points and flows across entities as well as platform economy 
                            principles within which such a solution is placed in.
                        </p>
                    }
                />
            </Tab.Pane>
            <Tab.Pane eventKey="7">
                <AccordionComponent 
                    step_name='Method'
                    description='UCM Use Case Engineering, Analysis and Qualitative Inquiry'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <p>
                            The tool is based on the Distributed Artificial Intelligence (DAI) approach and is designed to support the development of Cooperative, 
                            Connected and Automated Mobility (CCAM) solutions.

                            It provides a framework for building DAI-based CCAM solutions in the PE and comprises the creation of actor maps, identification of the involved entities, 
                            means of transport and transport mode, assuming different degrees of digitalization, data points and flows across entities as well as platform economy 
                            principles within which such a solution is placed in.
                        </p>
                    }
                />
            </Tab.Pane>
            
        </Tab.Content>
        </ div>
    </div>
    </Tab.Container>
);

export default function Approach () {

    const [isApproach, setIsApproach] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const navigateCockpit = () => {
        navigate('/Create');
    };

    const navigateStudy = () => {
        navigate('/Participate');
    }

    const info = 
        'This page describes the research direction of this work.' +         
        'Each core section is described, respectively.';

    const handleModalInfo = () => {        
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleApproach = () => {
        setIsApproach(!isApproach);
    }

    return (
      <Container fluid>
            {!isApproach && (
            <>
                {/* Intro */}
                <Row align='center'>      
                    <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Context and the Underlying Approach</b></h1>
                    <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                    <i>UCM</i> is a tool designed to explore required components, data and actors to conceptualize and build solutions, with a focus on Autonomous Mobility (AM) and Transportation.
                    </p>
                </Row>
                {/* Classification */}         
                <Row align='center'>
                    <h3 style={{marginTop: '30px', marginBottom: '30px'}}>Classification</h3>
                    <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                        It builts upon two developed semantic models, that is the Generic Use Case Ontology (GUCO) modules and the Platform Economy (PE) for Autonomous Mobility (AM) Ontology (PEAMONTO) module. 
                        <i> UCM</i> is a solution builder in the realm of autonomous mobility. It specifically addresses the distributed artificial intelligence (DAI) approach for Cooperative, Connected and Automated Mobility (CCAM) 
                        in the Platform Economy (PE) framework.      
                    <br /><br />
                        The purpose of this tool is to provide a structured approach to developing platform-based solutions in the context of autonomous mobility.
                        Modeled use cases will be analyzed and validated in order to propose a reference model for the development of platform-based solutions in the context of autonomous mobility.
                    </p>              
                </Row>
                {/* Learn about the approach */}
                <Row align='center'>
                <div>
                    <Button variant="outline-primary" style={{ marginRight: '10px' }} onClick={handleApproach}>Learn about the approach</Button>
                </div>            
                </Row>
            </>
            )}
            {/* Approach to Problem Solving and Contribution */}
            {isApproach && (
                <>
                <h4 align='center'>Approach to Problem Solving and Contribution</h4>
                <Row>
                    <Col md={6}>
                        <div>
                            <h3 className="text-start">Approach</h3>
                            <h6 className="text-start">Scope of this work</h6>                    
                        </div>
                    </Col>  
                    <Col md={6} className="d-flex justify-content-end align-items-center">
                        {/* toolbar */}
                        <div className="d-flex justify-content-end">                                                
                            <OverlayTrigger placement="top" overlay={<Tooltip>Sort by prompt.</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary">
                                    <i className="bi bi-sort-alpha-down"></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Sort by prompt.</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary">
                                    <i className="bi bi-sort-alpha-up"></i>
                                </Button>
                            </OverlayTrigger>
                            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>    
                            <OverlayTrigger placement="top" overlay={<Tooltip>Provide feedback.</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary">
                                    <i className="bi bi-magic"></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Create a new use case.</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={navigateCockpit}>
                                    <i className="bi bi-pencil-square"></i> New
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Participate in the study.</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={navigateStudy}>
                                    <i className="bi bi-cup-hot"></i> Participate
                                </Button>
                            </OverlayTrigger>
                            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>                                                                                                                                             
                            
                            <OverlayTrigger placement="top" overlay={<Tooltip>Go back</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleApproach}>
                                    <i className="bi bi-arrow-left"></i>
                                </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={<Tooltip>Learn more about this page.</Tooltip>}>
                                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleModalInfo}>
                                    <i className="bi bi-info-circle"></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </Col>
                </Row>
                <Row style={ { marginTop:'15px'} }>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <DetailsApproach />       
                    </Col>
                    <Col md={2}></Col>
                </Row>

                {showModal && (
                    <InfoModal onClose={handleCloseModal} info={info} />
                )}
                </>
            )}
      </Container>
    );
}

export { default as Approach } from './Approach';